import React, { Component } from 'react'
import axios from 'axios';
import { checkAuth } from '../../utils/auth';
import { post, authformpost } from '../../utils/service'
import SimpleReactValidator from 'simple-react-validator';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { imageValid } from '../../utils/imageValid';
import { Link, withRouter } from 'react-router-dom'

export default class AddCategory extends Component {
    constructor(props) {
        super(props)

        this.state = {
            cat_name: '',
            cat_icon: '',
            loading: false,
        }
        this.validator = new SimpleReactValidator();
    }
    handleChange = e => {
        e.preventDefault();
        this.setState({ [e.target.name]: e.target.value });
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ loading: true });
        var formdata = new FormData(e.target);
        if (this.validator.allValid()) {
            authformpost('/api/addCategory', formdata)
                .then((data) => {
                    toast.dark(data.data.msg,
                        { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3000 })
                    this.props.history.push('/category');
                }).catch((err) => {
                    this.setState({ loading: false });
                })
        } else {
            this.validator.showMessages();
            this.forceUpdate();
            this.setState({ loading: false });
        }
    }

    render() {
        return (
            <div>
             
                <div className="page-header mt-0 p-3">
                    <h3 className="mb-sm-0">Menu</h3>
                    <ol className="breadcrumb mb-0">
                        <li className="breadcrumb-item">
                            <Link to="/"><i className="fe fe-home"></i></Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">Menu</li>
                    </ol>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card shadow">
                            <div className="card-header">
                                <h2 className="mb-0">Menu Add</h2>
                            </div>
                            <form onSubmit={this.handleSubmit}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-4"></div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                Menu Name:
                                                    <input type="text" className="form-control" id="cat_name" name="cat_name" placeholder="Enter Menu Name" onChange={this.handleChange} value={this.state.cat_name} />
                                                {this.validator.message('cat_name', this.state.cat_name, 'required', { className: 'text-danger' })}

                                            </div>
                                            <div className="form-group">
                                                  Menu Role:
                                                    <select name="cat_role" id="cat_role" className="form-control" onChange={this.handleChange}>
                                                        <option value="1">-- Select Option --</option>
                                                        <option value="1">Have Submenu</option>
                                                        <option value="2">Do Not Have Submenu</option>
                                                    </select>
                                                
                                                {this.validator.message('cat_role', this.state.cat_role, 'required', { className: 'text-danger' })}

                                            </div>
                                            <div className="form-group">
                                                Menu Icon : (178X74)
                                                    <input type="file" className="form-control" name="cat_icon" id="cat_icon" onChange={(e) => imageValid(e)} onChange={this.handleChange} value={this.state.cat_icon} />
                                                {this.validator.message('cat_icon', this.state.cat_icon, 'required', { className: 'text-danger' })}

                                            </div>
                                            <div className="form-group">
                                                <center><button className="btn btn-success" disabled={this.state.loading}>{this.state.loading ? 'Loading...' : 'Submit'}</button></center>
                                            </div>
                                        </div>
                                        <div className="col-md-4"></div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
         
            </div>
        )
    }
}

import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { authpost, authget, post, authformpost, authdelete } from '../../utils/service'
// import {UserContext} from './../../content/App'
import { Link, withRouter } from 'react-router-dom'
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import * as CONSTANT from '../../utils/constant';

toast.configure();

export default function Viewuser(props) {
    const [users, setUsers] = useState([])
    const [open, setopen] = useState(false)
    const [open1, setopen1] = useState(false)
    const [open2, setopen2] = useState(false)
    const [userdetails, setUserdetails] = useState([]);
    const [consaltent, setConsaltent] = useState([])
    const [consaltent_id, setConsaltent_id] = useState('')
    const [user_id, setuser_id] = useState('')
    const [user_balance, setuser_balance] = useState('');
    const [purpose, setPurpose] = useState('');

    useEffect(() => {
        window.scrollTo(0, 0);
        authget(`/api/showClient`)
            .then((response) => {
                console.log(response)
                setUsers(response.data.data.get_user)
            })
            .catch(function (error) {
                console.log(error);
            });

        authget(`/api/showConsultant`)
            .then((response) => {
                console.log(response.data)
                setConsaltent(response.data.data.get_user)
            })
            .catch(function (error) {
                console.log(error);
            });

    }, [])

    const statusChange = (id) => {
        authget(`/api/statuschangeu?id=${id}`)
            .then((response) => {
                console.log(response.data.data)
                if (response.data.status === true) {
                    setUsers(response.data.data)
                    toast.dark(response.data.msg,
                        { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3000 })

                } else {
                    toast.dark(response.data.msg,
                        { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3000 })
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const libraryChange = (id) => {
        authget(`/api/lschange?id=${id}`)
            .then((response) => {
                console.log(response.data.data)
                if (response.data.status === true) {
                    setUsers(response.data.data)
                    toast.dark(response.data.msg,
                        { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3000 })

                } else {
                    toast.dark(response.data.msg,
                        { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3000 })
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const onCloseModal = () => {
        setopen(false)
    }
    const onCloseModal1 = () => {
        setopen1(false)
    }
    const onCloseModal2 = () => {
        setopen2(false)
    }
    const Viewuser = (data) => {
        setopen(true)
        setUserdetails(data);
    }
    const give_permission = (id) => {
        setopen1(true)
        setuser_id(id)
    }
    const due_payment = (id) => {
        setopen2(true)
        setuser_id(id)
    }
    const consaltentParmission = (e) => {
        // console.log(e.target.value)
        setConsaltent_id(e.target.value);
        setuser_balance(e.target.value);
       

    }
    const aaa = (e) =>{
        setPurpose(e.target.value)
    }
    const payment = () => {
        const data = {
            user_id,
            user_balance,
            purpose
        }
        if (user_balance != '') {
            if (purpose != '') {
            authpost('/api/user_due_payment', data)
                .then((res) => {
                    if (res.data.status == false) {
                        toast.error(res.data.msg, {
                            position: toast.POSITION.BOTTOM_CENTER,
                            autoClose: 3000,
                        });
                    } else {
                        setopen2(false)
                        toast.dark(res.data.msg, {
                            position: toast.POSITION.BOTTOM_CENTER,
                            autoClose: 3000,
                        });
                    }
                }).catch((err) => {
                    console.log(err)
                    // this.setState({ loading: false });
                })
            } else {
                toast.error('Please Enter Purpose', {
                    position: toast.POSITION.BOTTOM_CENTER,
                    autoClose: 3000,
                });
            }
        } else {
            toast.error('Please Enter Blance', {
                position: toast.POSITION.BOTTOM_CENTER,
                autoClose: 3000,
            });
        }
    }

    const consaltentParmissionChange = () => {
        const data = {
            user_id: user_id,
            consaltent_id: consaltent_id
        }
        if (consaltent_id != '') {
            post('/api/consaltent_assign', data)
                .then((res) => {
                    if (res.data.success == false) {
                        toast.error(res.data.msg, {
                            position: toast.POSITION.BOTTOM_CENTER,
                            autoClose: 3000,
                        });
                    } else {
                        setopen1(false)
                        toast.dark(res.data.msg, {
                            position: toast.POSITION.BOTTOM_CENTER,
                            autoClose: 3000,
                        });
                    }
                }).catch((err) => {
                    // this.setState({ loading: false });
                })
        } else {
            toast.error('Please Select Consaltent', {
                position: toast.POSITION.BOTTOM_CENTER,
                autoClose: 3000,
            });
        }
    }
    const abc=(id)=>{
        props.history.push('/payment_transaction?id=' + id)
    }
    return (
        <div>

            <Modal open={open1} onClose={onCloseModal1} >
                <h2>Choose Consaltent Name </h2>
                <div className="row">
                    <div className="col-12" style={{ width: "750px" }}>
                        <div className="card shadow">
                            <div className="card-header bg-transparent border-0">
                                <label>Choose Consaltent Name</label>
                                <select name="cars" id="cars" className="form-control" onChange={consaltentParmission}>
                                    <option value="" disabled selected>Select consaltent</option>
                                    {consaltent.map((object, i) =>
                                        <option value={object.id} key={i}>{object.name}({object.phone})</option>
                                    )}
                                </select><br />
                                <center><button className="btn btn-success" onClick={consaltentParmissionChange} > Submit </button></center>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>


            <Modal open={open2} onClose={onCloseModal2} >
                <h2>Due Payment</h2>
                <div className="row">
                    <div className="col-12" style={{ width: "750px" }}>
                        <div className="card shadow">
                            <div className="card-header bg-transparent border-0">
                                <label>Due Payment</label>
                                <input type="number" value={user_balance} name="user_balance" id="user_balance" className="form-control" placeholder="Enter Payment Balance" onChange={consaltentParmission} />
                                <label>Purpose</label>
                                <input type="text" value={purpose} name="purpose" id="purpose" className="form-control" placeholder="Enter Purpose" onChange={aaa} />

                                <br />
                                <center><button className="btn btn-success" onClick={payment} > Submit </button></center>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>


            <Modal open={open} onClose={onCloseModal} >
                <h2>User Details </h2>
                <div className="row">
                    <div className="col-12" style={{ width: "750px" }}>
                        <div className="card shadow">
                            <div className="card-header bg-transparent border-0">
                                <div className="gallery row">
                                    {userdetails !== null ?
                                        <>
                                            City: {userdetails.city}<br />
                                            Pin Code: {userdetails.pin_code}<br />
                                            State: {userdetails.state}<br />
                                            Extra Phone number: {userdetails.extra_phone_no}<br />
                                            <div> Profile Image:   <a href={`${CONSTANT.URL}/storage/${userdetails.profile_image}`} className="big"><img src={`${CONSTANT.URL}/storage/${userdetails.profile_image}`} alt="" title="Beautiful Image" style={{ width: "50px" }} /></a></div>
                                        </>
                                        :
                                        <>
                                            <p>Details Not Fillup By User</p>
                                        </>}

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </Modal>



            <div className="page-header mt-0 p-3">
                <h3 className="mb-sm-0">View User</h3>
                <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                        <Link to="/"><i className="fe fe-home"></i></Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">View User</li>
                </ol>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card shadow">
                        <div className="card-header">
                            <h2 className="mb-0">View User</h2>
                        </div>
                        <div className="card-body">
                            <MaterialTable
                                title="User list"
                                columns={[
                                    { title: 'Name', field: 'name' },
                                    { title: 'Email', field: 'email' },
                                    { title: 'Contact No', field: 'phone', type: 'numeric' },
                                    { title: 'Address', field: 'address' },
                                    { title: 'Library Status', field: 'status', render: (row) => <> {row.library_status == 1 ? <><center><span className="badge badge-pill badge-success">Activate</span><br />  <button className="btn btn-danger btn-sm btn-pill" onClick={() => libraryChange(row.user_id)}>Deactivate</button></center></> : <> <center><span className="badge badge-pill badge-danger">Deactivate</span><br /><button className="btn btn-success btn-sm btn-pill" onClick={() => libraryChange(row.user_id)}>Active</button></center></>}</> },
                                    { title: 'Consaltent Permission ', field: 'consaltent_id', render: (row) => <> <button className="btn btn-info btn-sm btn-pill" onClick={() => give_permission(row.id)}>Give Permission</button> </> },
                                    { title: 'Payment ', field: 'user_balance', render: (row) => <> <button className="btn btn-info btn-sm btn-pill" onClick={() => due_payment(row.id)}>Due Payment</button> </> },

                                    {
                                        title: "Status", field: 'status', render: (row) => <div className={row.status ? "active1" : "deactive1"}>
                                            {row.status === 1 ? "Active" : "Deactive"}
                                        </div>
                                    },
                                    {
                                        title: "Status", field: 'status', render: (row) => 
                                        <div>
                                            <button className="btn btn-success btn-sm" onClick={()=>abc(row.id)}> View Payment</button>
                                        </div>
                                    },
                                ]}
                                data={users}
                                actions={[
                                    {
                                        icon: 'visibility',
                                        tooltip: 'View User',
                                        onClick: (event, rowData) => {
                                            Viewuser(rowData.get_user1)
                                        }
                                    },
                                    rowData => ({
                                        icon: rowData.cat_status === 1 ? 'D' : 'A',
                                        tooltip: rowData.cat_status === 1 ? 'Disactive' : 'Active',
                                        onClick: (event, rowData) => statusChange(rowData.user_id),
                                    }),
                                ]}
                                options={{
                                    actionsColumnIndex: -1
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
       
       
        </div>
    )
}

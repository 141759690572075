import React, { useState, useEffect, useContext } from "react";
import MaterialTable from "material-table";
import { toast } from "react-toastify";
import axios from "axios";
import { checkAuth } from "../../utils/auth";
import {
  authput,
  authget,
  post,
  authformpost,
  authdelete,
} from "../../utils/service";
import "react-toastify/dist/ReactToastify.css";
import { Link, withRouter } from "react-router-dom";
import * as CONSTANT from "../../utils/constant";
import { Modal } from "react-responsive-modal";
var urlCrypt = require("url-crypt")(
  '~{ry*I)==yU/]9<7DPk!Hj"R#:-/Z7(hTBnlRS=4CXF'
);
toast.configure();
export default function ViewLibrary(props) {
  const [allLibrary, setLibrary] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
    authget(`/api/showLibrary`)
      .then((response) => {
        // console.log(response.data.allLibrary);
        setLibrary(response.data.allLibrary);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const statusChange = (data) => {
    authput(`/api/statusLibrary?laibrary_id=${data}`)
      .then((data) => {
        toast.dark(data.data.msg, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 3000,
        });
        const da = data.data.cat;
        setLibrary(da);
      })
      .catch((err) => {
        console.log(err);
        // this.setState({ loading: false });
      });
  };
  const editDetails = (data) => {
    var base64 = urlCrypt.cryptObj(data);
    props.history.push(`/editLibrary?data=${base64}`);
  };
  return (
    <div>
      <div className="page-header mt-0 p-3">
        <h3 className="mb-sm-0">view Library</h3>
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item">
            <Link to="/">
              <i className="fe fe-home"></i>
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            view Library
          </li>
        </ol>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="card shadow">
            <div className="card-header">
              <h2 className="mb-0">View Library</h2>
              <Link
                to="addlibrary"
                className="btn btn-success btn-sm"
                style={{ float: "right" }}
              >
                {" "}
                Add Library
              </Link>
            </div>
            <div className="card-body">
              <MaterialTable
                title="service list"
                columns={[
                  { title: "Library Name", field: "laibrary_name" },
                  {
                    title: "Library Category",
                    field: "laibrary_cat_id",
                    render: (rowData) =>
                      rowData.get_library_cat.laibrary_cat_name,
                  },
                  {
                    title: "Document",
                    field: "pdf",
                    render: (rowData) => (
                      <>
                        {rowData.librarytype === "Video" ? (
                          rowData.pdf
                        ) : (
                          <a
                            href={`${CONSTANT.URL}/storage/${rowData.pdf}`}
                            target="_blank"
                          >
                            <img
                              src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png"
                              style={{ width: 50 }}
                            />
                          </a>
                        )}
                      </>
                    ),
                  },
                  { title: "Library Type", field: "librarytype" },
                  {
                    title: "Status",
                    field: "library_status",
                    render: (row) => (
                      <div
                        className={row.library_status ? "active1" : "deactive1"}
                      >
                        {row.library_status === 1 ? "Active" : "Deactive"}
                      </div>
                    ),
                  },
                ]}
                data={allLibrary}
                actions={[
                  (rowData) => ({
                    icon: rowData.library_status == 1 ? "D" : "A",
                    tooltip:
                      rowData.library_status == 1 ? "Disactive" : "Active",
                    onClick: (event, rowData) =>
                      statusChange(rowData.laibrary_id),
                  }),
                  {
                    icon: "edit",
                    tooltip: "Save User",
                    onClick: (event, rowData) => editDetails(rowData),
                  },
                ]}
                options={{
                  actionsColumnIndex: -1,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { Component } from 'react'
import axios from 'axios';
import { checkAuth } from '../../utils/auth';
import { post, authformpost,get } from '../../utils/service'
import SimpleReactValidator from 'simple-react-validator';
import { toast } from 'react-toastify';
import { Link, withRouter } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css';
import { imageValid } from '../../utils/imageValid';
// import {UserContext} from './../../content/App'
toast.configure();
export default class Add_freelancer extends Component {
    constructor(props) {
        super(props)

        this.state = {
            name: '',
            phone: '',
            email: '',
            city_id:'',
            password:'',
            confirm_password:'',
            city:[],

            // video_thumblane: ''
        }
        this.validator = new SimpleReactValidator({
            messages: {
              in: 'Passwords need to match!'
            }
        })
    }
    componentDidMount(){
        get(`/api/viewCity`)
        .then((response) => {
            this.setState({'city':response.data.city})
        })
        .catch(function (error) {
            console.log(error);
        });
      }
    handleChange = e => {
        e.preventDefault();
        this.setState({ [e.target.name]: e.target.value });
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ loading: true });
        var formdata = {
            'name': this.state.name,
            'email': this.state.email,
            'phone': this.state.phone,
            'role_id': 4,
            'password': this.state.password
        };

        if (this.validator.allValid()) {
            post('/api/register1', formdata)
                .then((res) => {

                    console.log(res.data)
                    if (res.data.status == false) {
                        this.setState({
                          loading: false,
                        });
                          toast.error(res.data.response, {
                          position: toast.POSITION.BOTTOM_CENTER,
                          autoClose: 3000,
                        });
                    } else {
                        this.setState({
                          loading: false,
                        });
                        toast.dark("Freelancer Create Successfully", {
                          position: toast.POSITION.BOTTOM_CENTER,
                          autoClose: 3000,
                        });
                        this.props.history.push('/viewfreelancer');

                    }

                }).catch((err) => {
                    this.setState({ loading: false });
                })
        } else {
            this.validator.showMessages();
            this.forceUpdate();
            this.setState({ loading: false });
        }
    }
    render() {
        return (
            <div>
                <div className="page-header mt-0 p-3">
                    <h3 className="mb-sm-0">Freelancer Forms</h3>
                    <ol className="breadcrumb mb-0">
                        <li className="breadcrumb-item">
                            <Link to="/"><i className="fe fe-home"></i></Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">Freelancer Forms</li>
                    </ol>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card shadow">
                            <div className="card-header">
                                <h2 className="mb-0">Freelancer Add</h2>
                            </div>
                            <form onSubmit={this.handleSubmit}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Freelancer Name</label>
                                                <input type="text" className="form-control" name="name" id="name" placeholder="Enter Consaltent Name" onChange={this.handleChange} value={this.state.name} />
                                                {this.validator.message('name', this.state.name, 'required', { className: 'text-danger' })}
                                            </div>

                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Phone Number</label>
                                                <input type="number" className="form-control" name="phone" id="phone" placeholder="Enter Phone Number" onChange={this.handleChange} value={this.state.phone} />
                                                {this.validator.message('phone', this.state.phone, 'required', { className: 'text-danger' })}
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label className="form-label">Email Id</label>
                                                <input type="email" className="form-control" name="email" id="email" placeholder="Enter Email id" onChange={this.handleChange} value={this.state.email} />
                                                {this.validator.message('email', this.state.email, 'required', { className: 'text-danger' })}
                                            </div>
                                        </div>
                                        {/* <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">City</label>
                                                <select name="city_id" id="city_id" className="form-control" onChange={this.handleChange}>
                                                  <option value="" disabled selected> Select City</option>
                                                   {this.state.city.map((object,i)=>
                                                    <option value={object.id} key={i}>{object.city_name}</option>
                                                   )}

                                                </select>
                                                {this.validator.message('city_id', this.state.city_id, 'required', { className: 'text-danger' })}
                                            </div>
                                        </div> */}
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Password</label>
                                                <input type="text" className="form-control" name="password" id="password" placeholder="Enter Password" onChange={this.handleChange} value={this.state.password} />
                                                {this.validator.message('password', this.state.password, 'required|min:8|max:120', { className: 'text-danger' })}

                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Confirm Password</label>
                                                <input type="text" className="form-control" name="confirm_password" id="confirm_password" placeholder="Enter Confirm Password" onChange={this.handleChange} value={this.state.confirm_password} />
                                                {this.validator.message('confirm_password', this.state.confirm_password, `required|in:${this.state.password}`,{ className: 'text-danger' }, {messages: {in: 'Passwords need to match!'}})}

                                            </div>
                                        </div>
                                        {/* <div className="col-md-12">
                                            <div className="form-group mb-0">
                                                <label className="form-label">Thumbnail</label>
                                                <input type="file" className="form-control" name="video_thumblane" id="video_thumblane" placeholder="Video Thumblane" onChange={this.handleChange} value={this.state.video_thumblane} />
                                                {this.validator.message('video_thumblane', this.state.video_thumblane, 'required', { className: 'text-danger' })}
                                            </div>
                                        </div> */}
                                        <div className="col-md-12">
                                            <div className="form-group m"><br>
                                            </br>
                                                <center><button className="btn btn-success" disabled={this.state.loading?true:false}>Submit</button></center>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

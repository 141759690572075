import React, { useState, useEffect, useContext } from "react";
import MaterialTable from "material-table";
import { toast } from "react-toastify";
import axios from "axios";
import { checkAuth } from "../../utils/auth";
import {
  authput,
  authget,
  post,
  authformpost,
  authdelete,
} from "../../utils/service";
import * as CONSTANT from "../../utils/constant";
import "react-toastify/dist/ReactToastify.css";
import { Link, withRouter } from "react-router-dom";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
var urlCrypt = require("url-crypt")(
  '~{ry*I)==yU/]9<7DPk!Hj"R#:-/Z7(hTBnlRS=4CXF'
);

toast.configure();

function View_complete_service(props) {
  const [competeService, setcompeteService] = useState([]);
  const [open, setopen] = useState(false);
  const [serviceImage, setserviceImage] = useState([]);
  const [completeServiceid, setcompleteServiceid] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    authget(`/api/viewCompleteService`)
      .then((response) => {
        setcompeteService(response.data.completeService);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const onCloseModal = () => {
    setopen(false);
  };

  const abc = (data, id) => {
    setopen(true);
    setserviceImage(data);
    setcompleteServiceid(id);
  };
  const cba = (id) => {
    authdelete(`/api/Deletecompleteservice?id=${id}`)
      .then((res) => {
        toast.dark("Delete Successfully", {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 3000,
        });
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const addImage = (e) => {
    e.preventDefault();
    var formdata = new FormData(e.target);
    console.log(...formdata);
    authformpost(`/api/completeserviceimageAdd`, formdata)
      .then((res) => {
        console.log(res);
        toast.dark("Add Successfully", {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 3000,
        });
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const deleteSubmit = (complete_service_id) => {
    authget(
      `/api/deletecompleteservice?complete_service_id=${complete_service_id}`
    )
      .then((data) => {
        // console.log(data);

        toast.dark(data.data.msg, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 3000,
        });
        const ks = competeService.filter(
          (gs) => gs.complete_service_id != complete_service_id
        );
        setcompeteService(ks);
      })
      .catch((err) => {
        // this.setState({ loading: false });
      });
  };
  return (
    <div>
      <Modal open={open} onClose={onCloseModal}>
        <h2>Complete Service </h2>
        <div className="row">
          <div className="col-12" style={{ width: "750px" }}>
            <div className="card shadow">
              <div className="card-header bg-transparent border-0">
                <div className="gallery row">
                  {serviceImage.length != 0
                    ? serviceImage.map((object, i) => (
                        <div className="col-lg-4 hover15">
                          <button onClick={() => cba(object.id)}>
                            <i class="fas fa-trash-alt"></i>
                          </button>
                          <div className="card shadow overflow-hidden">
                            <a
                              href={`${CONSTANT.URL}/storage/${object.complete_service_icon}`}
                              className="big"
                            >
                              <img
                                src={`${CONSTANT.URL}/storage/${object.complete_service_icon}`}
                                alt=""
                                title="Beautiful Image"
                              />
                            </a>
                          </div>
                        </div>
                      ))
                    : null}
                </div>
                <form onSubmit={addImage}>
                  <div className="row">
                    Complete Service Image(360X175)
                    <input
                      type="hidden"
                      id="complete_service_id"
                      name="complete_service_id"
                      value={completeServiceid}
                    ></input>
                    <div className="col-md-10">
                      <input
                        type="file"
                        name="complete_service_icon"
                        className="form-control"
                      />
                    </div>
                    <div className="col-md-2">
                      <button type="submit" className="btn btn-success">
                        {" "}
                        Submit{" "}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <div className="page-header mt-0 p-3">
        <h3 className="mb-sm-0">Complete Service View</h3>
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item">
            <Link to="/">
              <i className="fe fe-home"></i>
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Complete Service View
          </li>
        </ol>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="card shadow">
            <div className="card-header">
              <h2 className="mb-0">Complete Service View</h2>
            </div>
            <div className="card-body">
              <MaterialTable
                title="Complete Service View"
                columns={[
                  {
                    title: "Complete Service Name",
                    field: "complete_service_name",
                  },
                  {
                    title: "Complete Service description",
                    field: "complete_service_des",
                  },
                ]}
                data={competeService}
                actions={[
                  (rowData) => ({
                    icon: "visibility",
                    tooltip: "View Service Image",
                    onClick: (event, rowData) => {
                      abc(rowData.get_complete_serviceimage, rowData.id);
                    },
                    disabled: rowData.birthYear < 2000,
                  }),

                  (rowData) => ({
                    icon: "edit",
                    tooltip: "Edit Complete Service",
                    onClick: (event, rowData) => {
                      var base64 = urlCrypt.cryptObj(rowData);
                      props.history.push(
                        `/edit_complete_service?data=${base64}`
                      );
                    },
                  }),
                  (rowData) => ({
                    icon: "delete",
                    tooltip: "Delete Complete Service",
                    onClick: (event, rowData) => {
                      deleteSubmit(rowData.complete_service_id);
                    },
                  }),
                ]}
                options={{
                  actionsColumnIndex: -1,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default withRouter(View_complete_service);

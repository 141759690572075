import React, { Component } from 'react'
import { imageValid } from '../../utils/imageValid';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import axios from 'axios';
import { checkAuth } from '../../utils/auth';
import { post, authformpost, authget, } from '../../utils/service'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SimpleReactValidator from "simple-react-validator";
import queryString from 'query-string';
import { Link, withRouter } from 'react-router-dom'

var urlCrypt = require('url-crypt')('~{ry*I)==yU/]9<7DPk!Hj"R#:-/Z7(hTBnlRS=4CXF');

// import {UserContext} from './../../content/App'

export default class Editservice extends Component {
    constructor(props) {
        super(props)
        let param = props.location.search;
        const parsed = queryString.parse(param);
        var backAgain = urlCrypt.decryptObj(parsed.data);
        console.log(backAgain);
        this.state = {
            shareholders: [{ name: '' }],
            cat_id: backAgain.cat_id,
            service_id:backAgain.service_id,
            subcat_id: backAgain.subcat_id,
            service_name: backAgain.service_name,
            service_des: backAgain.service_des,
            service_price: backAgain.service_price,
            max_price: backAgain.max_price,
            service_icon: [],
            loading: false,
            get_category: [],
            get_subcategory: []
        }
        this.validator = new SimpleReactValidator();
    }
   

    componentDidMount() {
        authget(`/api/showCategory`)
            .then((res) => {
                if (res.status == 200) {
                    this.setState({
                        get_category: res.data.cat,
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });

        authget(`/api/showSubcategory`)
            .then((res) => {
                if (res.status == 200) {
                    this.setState({
                        get_subcategory: res.data.data.get_subcategory,
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const fd = new FormData(e.target);
        fd.append("service_des", this.state.service_des);
        console.log(...fd)
        if (this.validator.allValid()) {
            authformpost("/api/updateService", fd)
                .then((res) => {
                    console.log(res);
                    console.log(res);
                    toast.dark("Service Update Successfully!", {
                        position: toast.POSITION.BOTTOM_CENTER,
                        autoClose: 3000,
                    });
                    this.props.history.push("/service");

                })
                .catch((err) => {
                    console.log(err);
                    this.setState({
                        loading: false,
                    });
                });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
            // this.setState({
            //     loading: false,
            // });
        }
    }
    render() {
        return (
            <div>
                <div>
                    <div className="page-header mt-0 p-3">
                        <h3 className="mb-sm-0">Service Forms</h3>
                        <ol className="breadcrumb mb-0">
                            <li className="breadcrumb-item">
                                <Link to="/"><i className="fe fe-home"></i></Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Service Forms</li>
                        </ol>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card shadow">
                                <div className="card-header">
                                    <h2 className="mb-0">Service Add</h2>
                                </div>
                                <form onSubmit={this.handleSubmit}>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <input type="hidden" name="service_id" id="service_id" value={this.state.service_id}></input>
                                                <div className="form-group">
                                                    <label className="form-label">Service Name</label>
                                                    <input type="text" className="form-control" id="service_name" name="service_name" placeholder="Service Name" value={this.state.service_name} onChange={(e) => this.setState({ service_name: e.target.value, })} />
                                                    {this.validator.message(
                                                        "service_name",
                                                        this.state.service_name,
                                                        "required",
                                                        { className: "text-danger" }
                                                    )}

                                                </div>

                                                <div className="form-group">
                                                    <label className="form-label">Service Price</label>
                                                    <input type="number" className="form-control" name="service_price" placeholder="Min Price" value={this.state.service_price} onChange={(e) => this.setState({ service_price: e.target.value, })} />
                                                    {this.validator.message(
                                                        "service_price",
                                                        this.state.service_price,
                                                        "required",
                                                        { className: "text-danger" }
                                                    )}
                                                </div>

                                              
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label">Category Name</label>
                                                    <select name="cat_id" className="form-control" id="cat_id" onChange={(e) => this.setState({ cat_id: e.target.value, })} >
                                                        <option value="" disabled selected>-- Select Option --</option>
                                                        {this.state.get_category.map((obj, i) =>
                                                            <option value={obj.id} key={obj.cat_id} selected={obj.id == this.state.cat_id ? true : false}>{obj.cat_name}</option>
                                                        )}
                                                    </select>
                                                    {this.validator.message(
                                                        "cat_id",
                                                        this.state.cat_id,
                                                        "required",
                                                        { className: "text-danger" }
                                                    )}
                                                </div>
                                                <div className="form-group ">
                                                    <label className="form-label">Subcategory Name</label>
                                                    <select name="subcat_id" className="form-control" id="subcat_id" onChange={(e) => this.setState({ subcat_id: e.target.value, })} >
                                                        <option value="" disabled selected>-- Select Option --</option>
                                                        {this.state.get_subcategory.filter(obj => obj.cat_id === this.state.cat_id).map((obj, i) =>

                                                            <option value={obj.id} key={obj.subcat_id} selected={obj.id == this.state.subcat_id ? true : false}>{obj.subcat_name}</option>

                                                        )}
                                                    </select>
                                                    {this.validator.message(
                                                        "subcat_id",
                                                        this.state.subcat_id,
                                                        "required",
                                                        { className: "text-danger" }
                                                    )}
                                                </div>
                                               

                                            </div>

                                            <div className="col-md-12">
                                            <div className="form-group mb-0">
                                                    <label className="form-label">Service Desciption</label>
                                                    <CKEditor
                                                        editor={ClassicEditor}
                                                        data={this.state.service_des}
                                                        onReady={editor => {
                                                            // You can store the "editor" and use when it is needed.
                                                            console.log('Editor is ready to use!', editor);
                                                        }}
                                                        onChange={(event, editor) => {
                                                            const data = editor.getData();
                                                            this.setState({ service_des: data })
                                                            // console.log(data);
                                                        }}
                                                        onBlur={(event, editor) => {
                                                            console.log('Blur.', editor);
                                                        }}
                                                        onFocus={(event, editor) => {
                                                            console.log('Focus.', editor);
                                                        }}
                                                    />
                                                </div>
                                                </div>
                                     

                                          
                                            <div className="col-md-12"><br></br>
                                                <button type="submit" className="btn btn-success">Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

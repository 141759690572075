import AddSubCategory from '../components/Subcategory_components/AddSubcategory'

import {connect} from 'react-redux'
import {addToCart} from '../services/Actions/actions'
const mapStateToProps=state=>({
    data:state.addItems
})
const mapDispatchToProps=dispatch=>({
    

})
export default connect(mapStateToProps,mapDispatchToProps)(AddSubCategory)
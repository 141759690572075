import React,{ Component,Suspense,lazy} from 'react'
import {connect} from 'react-redux'
import {addToCart} from '../services/Actions/actions'
import Loding from "../components/Home_componets/Loding";
const View_city=lazy(()=>import('../components/City_component/View_city'))


class ViewCityComponent extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             
        }
    }
    
    render() {
        return (
            <div>
                 <Suspense   fallback={<div> <Loding/></div>}> 
                      <View_city {...this.props}/>  
                 </Suspense>
            </div>
        )
    }
}
const mapStateToProps=state=>({
    data:state.addItems
})
const mapDispatchToProps=dispatch=>({
    
})
export default connect(mapStateToProps,mapDispatchToProps)(ViewCityComponent)

import React from 'react'
import {Link} from 'react-router-dom'
export default function Default404() {
    return (
        <div>
            <div className="page bg-gradient-primary">
                <div className="page-main">
                    <div id="notfound">
                        <div className="notfound">
                            <h1><span className=""><i className="far fa-frown"></i></span>ops!</h1>
                            <h2 className="mb-0">Error 404 : Page Not Found</h2>
                            <Link to="/" className="btn btn-primary btn-pill mt-4">Go back</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

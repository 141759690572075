
import React,{ Component,Suspense,lazy} from 'react'
import {connect} from 'react-redux'
import {addToCart} from '../services/Actions/actions'
import Loding from "../components/Home_componets/Loding";

const ViewCategory=lazy(()=>import('../components/Category_components/ViewCategory'))


function ViewCategoryContainer(props) {
    return (
        <div>
            <Suspense   fallback={<div> <Loding/></div>}> 
                    <ViewCategory  {...props}/>    
            </Suspense>   
        </div>
    )
}

const mapStateToProps=state=>({
    data:state.addItems
})
const mapDispatchToProps=dispatch=>({


})
export default connect(mapStateToProps,mapDispatchToProps)(ViewCategoryContainer)

import React,{Component,Suspense,lazy} from 'react'
// import View_inquiry from '../components/Inquiry_component/View_inquiry'
import {connect} from 'react-redux'
import {addToCart} from '../services/Actions/actions'
import Loding from "../components/Home_componets/Loding";
const View_inquiry=lazy(()=>import('../components/Inquiry_component/View_inquiry'))

function ViewInqueryComponent() {
    return (
        <div>
             <Suspense fallback={<div> <Loding/></div>}> 
                    <View_inquiry/>    
            </Suspense> 
        </div>
    )
}

const mapStateToProps=state=>({
    data:state.addItems
})
const mapDispatchToProps=dispatch=>({
    

})
export default connect(mapStateToProps,mapDispatchToProps)(ViewInqueryComponent)
import React, { Component } from 'react'
import axios from 'axios';
import { checkAuth } from '../../utils/auth';
import { post, authformpost } from '../../utils/service'
import SimpleReactValidator from 'simple-react-validator';
import { toast } from 'react-toastify';
import { Link, withRouter } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css';
import { imageValid } from '../../utils/imageValid';
// import {UserContext} from './../../content/App'
toast.configure();
export default class Add_banner extends Component {
    constructor(props) {
        super(props)

        this.state = {
            banner_name: '',
            banner_icon: '',
            sequence:'',
        }
        this.validator = new SimpleReactValidator();
    }
    handleChange = e => {
        e.preventDefault();
        this.setState({ [e.target.name]: e.target.value });
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ loading: true });
        var formdata = new FormData(e.target);
        var formdata1 = [...formdata];
        console.log(formdata1);
        if (this.validator.allValid()) {
            authformpost('/api/addBanner', formdata)
                .then((data) => {
                    toast.dark(data.data.msg,
                        { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3000 })
                    this.props.history.push('/viewbanner');
                }).catch((err) => {
                    this.setState({ loading: false });
                })
        } else {
            this.validator.showMessages();
            this.forceUpdate();
            this.setState({ loading: false });
        }
    }
    render() {
        return (
            <div>
                <div className="page-header mt-0 p-3">
                    <h3 className="mb-sm-0">Banner Forms</h3>
                    <ol className="breadcrumb mb-0">
                        <li className="breadcrumb-item">
                            <Link to="/"><i className="fe fe-home"></i></Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">Banner Forms</li>
                    </ol>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card shadow">
                            <div className="card-header">
                                <h2 className="mb-0">Banner Add</h2>
                            </div>
                            <form onSubmit={this.handleSubmit}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Banner Name</label>
                                                <input type="text" className="form-control" name="banner_name" id="banner_name" placeholder="Banner Name" onChange={this.handleChange} value={this.state.banner_name} />
                                                {this.validator.message('banner_name', this.state.banner_name, 'required', { className: 'text-danger' })}
                                            </div>

                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Banner Image (1349X562)</label>
                                                <input type="file" className="form-control" name="banner_icon" id="banner_icon" placeholder="Banner Image" onChange={this.handleChange} value={this.state.banner_icon} />
                                                {this.validator.message('banner_icon', this.state.banner_icon, 'required', { className: 'text-danger' })}
                                            </div>

                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group mb-0">
                                                <label className="form-label">Sequence</label>
                                                <input type="number" className="form-control" name="sequence" id="sequence" placeholder="Banner sequence" onChange={this.handleChange} value={this.state.sequence} />
                                                {this.validator.message('sequence', this.state.sequence, 'required', { className: 'text-danger' })}
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group m"><br>
                                            </br>
                                                <center><button className="btn btn-success">Submit</button></center>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

import React, { Component } from "react";
import axios from "axios";
import { checkAuth } from "../../utils/auth";
import { post, authformpost } from "../../utils/service";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import queryString from "query-string";
import { Link, withRouter } from "react-router-dom";
export default class EditSCategory extends Component {
  constructor(props) {
    super(props);
    let url = this.props.location.search;
    let params = queryString.parse(url);
    console.log(params);
    this.state = {
      id: params.id,
    };
  }
  handleSubmit = (e) => {
    e.preventDefault();
    var formdata = new FormData(e.target);
    authformpost("/api/Edit_Service_provider_category", formdata)
      .then((data) => {
        toast.dark(data.data.msg, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 3000,
        });
        this.props.history.push("/viewscategory");
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  render() {
    return (
      <div>
        <div className="page-header mt-0 p-3">
          <h3 className="mb-sm-0">service Provider Category Edit</h3>
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <Link to="/">
                <i className="fe fe-home"></i>
              </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              service Provider Edit
            </li>
          </ol>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card shadow">
              <div className="card-header">
                <h2 className="mb-0">service Provider Edit</h2>
              </div>
              <form onSubmit={this.handleSubmit}>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <input
                          type="hidden"
                          name="id"
                          value={this.state.id}
                          id="id"
                        ></input>
                        <label className="form-label">
                          service Provider Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="example-text-input"
                          id="s_category_name"
                          name="s_category_name"
                          placeholder="service Provider  Name"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group m">
                        <button className="btn btn-success">Submit</button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

import React, { Component } from 'react'
import axios from 'axios';
import { checkAuth } from '../../utils/auth';
import { post, authformpost } from '../../utils/service'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, withRouter } from 'react-router-dom'
import queryString from 'query-string';
import { imageValid } from '../../utils/imageValid';
import * as CONSTANT from '../../utils/constant';
var urlCrypt = require('url-crypt')('~{ry*I)==yU/]9<7DPk!Hj"R#:-/Z7(hTBnlRS=4CXF');
export default class Editsubcategory extends Component {
    constructor(props) {
        super(props)
        let param = this.props.location.search;
        const parsed = queryString.parse(param);
        var backAgain = urlCrypt.decryptObj(parsed.data);

        this.state = {
            subcat_details: backAgain,
            loading: false,
        }
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ loading: true });
        var formdata = new FormData(e.target);
        authformpost('/api/updateSubcategory', formdata)
            .then((data) => {
                toast.dark(data.data.msg,
                    { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3000 })
                this.props.history.push('/subcategory');
                this.setState({ loading: false });

            }).catch((err) => {
                this.setState({ loading: false });
            })
    }

    handleChange = e => {
        e.preventDefault();
        this.setState({ [e.target.name]: e.target.value });
    }

    render() {
        return (
            <div>
                <div className="page-header mt-0 p-3">
                    <h3 className="mb-sm-0">Edit Submenu</h3>
                    <ol className="breadcrumb mb-0">
                        <li className="breadcrumb-item">
                            <Link to="/"><i className="fe fe-home"></i></Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">Edit Submenu</li>
                    </ol>
                </div>
                <div className="row">
                    <div className="col-md-12">

                        <div className="card shadow">
                            <div className="card-header">
                                <h2 className="mb-0">Edit Submenu</h2>
                            </div>
                            <form onSubmit={this.handleSubmit}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-4"></div>
                                        <div className="col-md-4">
                                            <input type="hidden" id="subcat_id" name="subcat_id" value={this.state.subcat_details.subcat_id}></input>
                                            <div className="form-group">
                                                <label className="form-label">Submenu Name</label>
                                                <input type="text" className="form-control" name="subcat_name" id="subcat_name" placeholder="Submenu name" defaultValue={this.state.subcat_details.subcat_name} required />
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label">Submenu Icon (178X74)</label>  <img src={`${CONSTANT.URL}/storage/${this.state.subcat_details.subcat_icon}`} style={{ width: 30, }}></img>
                                                <input type="file" className="form-control" name="subcat_icon" id="subcat_icon" defaultValue="" />
                                            </div>
                                            <div className="form-group">
                                                <center><button className="btn btn-success" disabled={this.state.loading}>{this.state.loading ? 'Loading...' : 'Submit'}</button></center>
                                            </div>
                                        </div>
                                        <div className="col-md-4"></div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

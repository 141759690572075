import React, { Component } from 'react'
import axios from 'axios';
import { checkAuth } from '../../utils/auth';
import { post, authformpost,authget } from '../../utils/service'
import SimpleReactValidator from 'simple-react-validator';
import { toast } from 'react-toastify';
import { Link, withRouter } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css';
import { imageValid } from '../../utils/imageValid';
export default class Add_coupon extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            service:[],
             
        }
        this.validator = new SimpleReactValidator();

    }
    componentDidMount(){
        window.scrollTo(0, 0);
        authget(`/api/showService`)
            .then((response) => {
                const ser=response.data.service.filter(gs=>gs.service_status == 1);
                this.setState({
                    service:ser
                })
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    handleChange = e => {
        e.preventDefault();
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ loading: true });
        var formdata = new FormData(e.target);
        var formdata1 = [...formdata];
        console.log(formdata1)
        if (this.validator.allValid()) {
            authformpost('/api/addcoupon', formdata)
                .then((data) => {
                    console.log(data)
                    toast.dark(data.data.msg,
                        { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3000 })
                    this.props.history.push('/view_coupon');
                }).catch((err) => {
                    this.setState({ loading: false });
                })
        } else {
            this.validator.showMessages();
            this.forceUpdate();
            this.setState({ loading: false });
        }
    }
    
    render() {
        return (
            <div>
              <div>
                    <div className="page-header mt-0 p-3">
                        <h3 className="mb-sm-0">Coupon</h3>
                        <ol className="breadcrumb mb-0">
                            <li className="breadcrumb-item">
                                <Link to="/"><i className="fe fe-home"></i></Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Coupon</li>
                        </ol>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card shadow">
                                <div className="card-header">
                                    <h2 className="mb-0">Coupon</h2>
                                </div>
                                <form onSubmit={this.handleSubmit}>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="form-label">Select service </label>
                                                    <select name="service_id" id="service_id" className="form-control"  onChange={this.handleChange}>
                                                        <option value="" selected disabled>--Select Service--</option>
                                                       {this.state.service.map((object,i)=>
                                                        <option value={object.id}>{object.service_name}</option>
                                                       )}
                                                    </select>
                                                    {this.validator.message('service_id', this.state.service_id, 'required', { className: 'text-danger' })}
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="form-label">Coupon Name</label>
                                                    <input type="text" className="form-control" name="coupon_name" id="coupon_name" placeholder="Coupon Name" onChange={this.handleChange} value={this.state.coupon_name} />
                                                    {this.validator.message('coupon_name', this.state.coupon_name, 'required', { className: 'text-danger' })}
                                                </div>
                                            </div>
                                            {/* <div className="col-md-6">
                                                <div className="form-group mb-0">
                                                    <label className="form-label">Coupon Image</label>
                                                    <input type="file" className="form-control" name="coupon_image" id="coupon_image" placeholder="Coupon Image"  required/>
                                                </div>
                                            </div> */}
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label">Coupon Code (Optional)</label>
                                                    <input type="text" className="form-control" name="coupon_code" id="coupon_code" placeholder="Coupon Code" onChange={this.handleChange} value={this.state.coupon_code} />
                                                    {/* {this.validator.message('coupon_code', this.state.coupon_code, 'required', { className: 'text-danger' })} */}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label">Coupon Value</label>
                                                    <input type="number" className="form-control" name="coupon_value" id="coupon_value" placeholder="Coupon Value" onChange={this.handleChange} value={this.state.coupon_value} />
                                                    {this.validator.message('coupon_value', this.state.coupon_value, 'required', { className: 'text-danger' })}
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group m"><br>
                                                </br>
                                                    <center><button className="btn btn-success">Submit</button></center>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
   
            </div>
        )
    }
}

import React from 'react'
import Viewuser from './Viewuser'

export default function Payment(props) {
    return (
        <div>
            {/* <View_Payment {...props}/> */}
            <Viewuser {...props}/>
        </div>
    )
}

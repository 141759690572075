import React, { Component } from 'react'
import { imageValid } from '../../utils/imageValid';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import axios from 'axios';
import { checkAuth } from '../../utils/auth';
import { post, authformpost, authget } from '../../utils/service'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SimpleReactValidator from "simple-react-validator";
import { Link, withRouter } from 'react-router-dom'
// import {UserContext} from './../../content/App'
toast.configure();
export default class Addservice extends Component {
    constructor(props) {
        super(props)
        this.state = {
            shareholders: [{ name: '' }],
            cat_id: '',
            subcat_id: '',
            service_name: '',
            service_des: '',
            service_price: '',
            max_price: '',
            service_icon: [],
            loading: false,
            get_category: [],
            get_subcategory: [],
            cat_role:1
        }
        this.validator = new SimpleReactValidator();
    }
    handleShareholderNameChange = (idx) => (evt) => {
        const newShareholders = this.state.shareholders.map((shareholder, sidx) => {
            if (idx !== sidx) return shareholder;
            return { ...shareholder, name: evt.target.value };
        });
        this.setState({ shareholders: newShareholders });
    }
    handleAddShareholder = () => {
        this.setState({ shareholders: this.state.shareholders.concat([{ name: '' }]) });
    }

    handleRemoveShareholder = (idx) => () => {
        this.setState({ shareholders: this.state.shareholders.filter((s, sidx) => idx !== sidx) });
    }
    componentDidMount() {
        authget(`/api/showCategory`)
            .then((res) => {

                if (res.status == 200) {

                    this.setState({
                        get_category: res.data.cat,
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });

        authget(`/api/showSubcategory`)
            .then((res) => {
                if (res.status == 200) {
                    this.setState({
                        get_subcategory: res.data.data.get_subcategory,
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const fd = new FormData(e.target);        
        fd.append("cat_id", this.state.cat_id);
        fd.append("service_des", this.state.service_des);

        console.log(...fd)
        if (this.validator.allValid()) {
            // this.setState({
            //   loading: true,
            // });
            authformpost("/api/addService", fd)
                .then((res) => {
                    console.log(res);


                    toast.dark("Service Create Successfully", {
                        position: toast.POSITION.BOTTOM_CENTER,
                        autoClose: 3000,
                    });
                    this.props.history.push("/service");

                })
                .catch((err) => {
                    console.log(err);
                    this.setState({
                        loading: false,
                    });
                });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
            this.setState({
                loading: false,
            });
        }

    }






    render() {
        return (
            <div>
                <div>
                    <div className="page-header mt-0 p-3">
                        <h3 className="mb-sm-0">Service Forms</h3>
                        <ol className="breadcrumb mb-0">
                            <li className="breadcrumb-item">
                                <Link to="/"><i className="fe fe-home"></i></Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Service Forms</li>
                        </ol>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card shadow">
                                <div className="card-header">
                                    <h2 className="mb-0">Service Add</h2>
                                </div>
                                <form onSubmit={this.handleSubmit}>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label">Service Name</label>
                                                    <input type="text" className="form-control" id="service_name" name="service_name" placeholder="Service Name" value={this.state.service_name} onChange={(e) => this.setState({ service_name: e.target.value, })} />
                                                    {this.validator.message(
                                                        "service_name",
                                                        this.state.service_name,
                                                        "required",
                                                        { className: "text-danger" }
                                                    )}

                                                </div>
                                                <div className="form-group">
                                                    <label className="form-label">Service Image (270X216)</label>
                                                    <input type="file" className="form-control" name="service_icon" id="service_icon"   required />

                                                </div>
                                                
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label">Category Name</label>
                                                    <select name="cat_id" className="form-control" id="cat_id" onChange={(e) => this.setState({ cat_id: e.target.value.split("|")[0],cat_role: e.target.value.split("|")[1] })} >
                                                        <option value="" disabled selected>-- Select Option --</option>
                                                        {this.state.get_category.map((obj, i) =>
                                                            <option value={`${obj.id}|${obj.cat_role}`} key={obj.cat_id}>{obj.cat_name}</option>
                                                        )}
                                                    </select>
                                                    {this.validator.message(
                                                        "cat_id",
                                                        this.state.cat_id,
                                                        "required",
                                                        { className: "text-danger" }
                                                    )}
                                                </div>
                                                {this.state.cat_role == 1?
                                                <div className="form-group ">
                                                    <label className="form-label">Subcategory Name</label>
                                                    <select name="subcat_id" className="form-control" id="subcat_id" onChange={(e) => this.setState({ subcat_id: e.target.value, })} >
                                                        <option value="" disabled selected>-- Select Option --</option>
                                                        {this.state.get_subcategory.map((obj, i) =>
                                                            obj.cat_id == this.state.cat_id ?
                                                                <option value={obj.id} key={obj.subcat_id}>{obj.subcat_name}</option>
                                                                : null
                                                        )}
                                                    </select>
                                                    {/* {this.validator.message(
                                                        "subcat_id",
                                                        this.state.subcat_id,
                                                        "required",
                                                        { className: "text-danger" }
                                                    )} */}
                                                </div>
                                                :
                                                <input type="hidden" value="1" name="subcat_id"></input>
                                                }
                                               

                                            </div>
                                            <div className="col-md-12">
                                            <div className="form-group">
                                                    <label className="form-label">Service Price</label>
                                                    <input type="number" className="form-control" name="service_price" placeholder="Service Price" onChange={(e) => this.setState({ service_price: e.target.value, })} />
                                                    {this.validator.message(
                                                        "service_price",
                                                        this.state.service_price,
                                                        "required",
                                                        { className: "text-danger" }
                                                    )}
                                                </div>
                                                </div>
                                            <div className="col-md-12">
                                                <div className="form-group mb-0">
                                                    <label className="form-label">Service Desciption</label>
                                                    <CKEditor
                                                        editor={ClassicEditor}
                                                        data=""
                                                        onReady={editor => {
                                                            // You can store the "editor" and use when it is needed.
                                                            console.log('Editor is ready to use!', editor);
                                                        }}
                                                        onChange={(event, editor) => {
                                                            const data = editor.getData();
                                                            this.setState({ service_des: data })
                                                            // console.log(data);
                                                        }}
                                                        onBlur={(event, editor) => {
                                                            console.log('Blur.', editor);
                                                        }}
                                                        onFocus={(event, editor) => {
                                                            console.log('Focus.', editor);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            {/* <div className="col-md-6">
                                                <div className="form-group mb-0">
                                                    <label className="form-label">Feture</label>
                                                    {this.state.shareholders.map((shareholder, idx) => (
                                                        <div className="shareholder">
                                                            <div className="row">
                                                                <div className="col-md-10">
                                                                    <div className="form-group mb-0">
                                                                        <input
                                                                            type="text"
                                                                            placeholder="Enter Feture Name"
                                                                            name="feature_name[]"
                                                                            id="feature_name"
                                                                            value={shareholder.name} className="form-control"
                                                                            onChange={this.handleShareholderNameChange(idx)}
                                                                        /><br></br>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-2">
                                                                    <button type="button" onClick={this.handleRemoveShareholder(idx)} className="btn btn-danger btn-sm">X</button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    ))}
                                                    <center>  <button type="button" onClick={this.handleAddShareholder} className="btn btn-info">Add Field</button></center>

                                                </div>
                                            </div> */}

                                            <div className="col-md-12"><br></br>
                                                <button type="submit" className="btn btn-success">Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

import React, { useState, useEffect, useContext } from 'react'
import MaterialTable from 'material-table'
import { toast } from 'react-toastify';
import axios from 'axios';
import { checkAuth } from '../../utils/auth';
import { authput, authget, post, authformpost, authdelete } from '../../utils/service'
import 'react-toastify/dist/ReactToastify.css';
import { Link, withRouter } from 'react-router-dom'

toast.configure();
export default function Review() {
    const [review, setReview] = useState([])
    useEffect(() => {
        window.scrollTo(0, 0);
        authget(`/api/showreview`)
            .then((response) => {
                console.log(response.data.showreview)
                // setReview(response.data.carear)
                setReview(response.data.showreview)
                 
            })
            .catch(function (error) {
                console.log(error);
            });

    }, [])
    const activeStatus=(id)=>{
        authget(`/api/reviewchange?id=${id}`)
            .then((response) => {
                console.log(response.data.showreview)
                // setReview(response.data.carear)
                setReview(response.data.showreview)
                toast.dark('Status Change Successfully',  
                {position: toast.POSITION.BOTTOM_CENTER,autoClose:3000})
          
            })
            .catch(function (error) {
                console.log(error);
            });  
    }
    return (
        <div>
            
            <div className="page-header mt-0 p-3">
                <h3 className="mb-sm-0">View Review</h3>
                <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                        <Link to="/"><i className="fe fe-home"></i></Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">View Review</li>
                </ol>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card shadow">
                        <div className="card-header">
                            <h2 className="mb-0">View Review</h2>
                        </div>
                        <div className="card-body">
                            <MaterialTable
                                title="Review list"
                                columns={[
                                    { title: 'Name', field: 'name' ,render: rowData => rowData.get_user.name },
                                    { title: 'Retting', field: 'retting',render: (row) => <div className="btn btn-success btn-sm">{row.retting} <i class="fa fa-star" aria-hidden="true"></i></div>},
                                    { title: 'Comment', field: 'comment'},
                                    {
                                        title: "Status", field: 'status', render: (row) => <div className={row.review_status ? "active1" : "deactive1"}>
                                            {row.review_status === 1 ? "Active" : "Deactive"}
                                        </div>
                                    },
                                ]}
                                data={review}
                                actions={[
                                    rowData => ({
                                        icon: rowData.city_status === 1 ? 'D' : 'A',
                                        tooltip: rowData.city_status === 1 ? 'Disactive' : 'Active',
                                        onClick: (event, rowData) => {activeStatus(rowData.id)},
                                        disabled: rowData.birthYear < 2000
                                    }),
                                ]}

                                options={{
                                    actionsColumnIndex: -1
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

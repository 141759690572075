import React, { Component } from 'react'
import MaterialTable from 'material-table'
import { toast } from 'react-toastify';
import axios from 'axios';
import { checkAuth } from '../../utils/auth';
import { authput, authget, post, authformpost, authdelete } from '../../utils/service'
import * as CONSTANT from '../../utils/constant';
import 'react-toastify/dist/ReactToastify.css';
import { Link, withRouter } from 'react-router-dom'
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
toast.configure();
export default class View_Coupon extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            coupon:[],
            open:false,
        }
    }
     onCloseModal = () => {
        this.setState({
            open:false
        })
    }
    componentDidMount(){
        authget('/api/viewcoupon')
        .then((data) => {
            console.log(data)
           this.setState({
            coupon:  data.data.allcoupon

           })
        }).catch((err) => {
            this.setState({ loading: false });
        })
    }

    deleteCoupon=(id)=>{
        authdelete(`/api/deletecoupon?id=${id}`)
            .then((response) => {
                
                if(response.status === 200){
                     var a = this.state.coupon.filter(gs=>gs.id !== id)
                     this.setState({
                        coupon:a 
                     })
                     toast.dark('Coupon Delete Successful',
                     { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3000 })

                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    
    
    render() {
        return (
            <div>
            <Modal open={this.stateopen} onClose={this.onCloseModal} >
                <h2>Coupon </h2>
            </Modal>
            <div className="page-header mt-0 p-3">
                <h3 className="mb-sm-0">Coupon View</h3>
                <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                        <Link to="/"><i className="fe fe-home"></i></Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Coupon View</li>
                </ol>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card shadow">
                        <div className="card-header">
                            <h2 className="mb-0">Coupon View</h2>
                            <Link to="add_coupon" className="btn btn-success btn-sm" style={{ "float": "right" }}> Add Coupon</Link>

                        </div>
                        <div className="card-body">
                            <MaterialTable
                                title="Coupon View"
                                columns={[
                                    { title: 'Coupon Name', field: 'coupon_name' },
                                    { title: 'Coupon Code', field: 'coupon_code' },
                                    {title: 'coupon value', field: 'coupon_value'},
                                    {title: 'Service', field: 'service_id',render: rowData =><>{rowData.get_service_coupon.service_name}</>},
                                  ]}
                                data={this.state.coupon}
                                actions={[
                                    rowData => ({
                                        icon: 'delete',
                                        tooltip: 'Delete Coupon',
                                        onClick: (event, rowData) => {this.deleteCoupon(rowData.id)},
                                        disabled: rowData.birthYear < 2000
                                    }),
                                ]}
                                options={{
                                    actionsColumnIndex: -1
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>

        </div>
  
        )
    }
}

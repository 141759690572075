import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, withRouter } from 'react-router-dom'
import { authget, authpost } from '../../utils/service'
toast.configure();

export default class Add_inquery extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      city: [],
      city_id: '',
      user_id: '',
      client_name: '',
      client_email: '',
      client_phone: '',
      client_address: '',
      client_msg: '',
      coupon_amount: 0,
      client_id: '',
      service_id: '',
      coupon_code: '',
      service: [],
      client: [],
      classd: '',
      msg: '',
      with_coupon_price: 0,
      service_price: 0,
    }
    this.validator = new SimpleReactValidator();
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      'service_id': this.state.service_id,
      'city_id': this.state.city_id,
      'user_id': this.state.client_id,
      'service_id': this.state.service_id,
      'client_name': this.state.client_name,
      'client_email': this.state.client_email,
      'client_phone': this.state.client_phone,
      'client_address': this.state.client_address,
      'client_msg': this.state.client_msg,
      'service_price': this.state.service_price,
      'with_coupon_price': this.state.with_coupon_price,
    }
    if (this.validator.allValid()) {
      this.setState({
        loading: true,
      });
      authpost("/api/addEnquery", data)
        .then((res) => {
          console.log(res);
          if (res.status == 404 || res.status == 400) {
            this.setState({
              loading: false,
            });
            toast.error(res.data.detail, {
              position: toast.POSITION.BOTTOM_CENTER,
              autoClose: 3000,
            });
          } else {
            this.setState({
              loading: false,
            });
            toast.dark("Enquery Create Successfully", {
              position: toast.POSITION.BOTTOM_CENTER,
              autoClose: 3000,
            });
            this.props.history.push("/viewinquery");
          }
        })

        .catch((err) => {
          console.log(err);
          this.setState({
            loading: false,
          });
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
      this.setState({
        loading: false,
      });
    }
  };

  handleChange = (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.name === 'client_id') {
      this.setState({
        client_name: e.target.value.split("|")[1],
        client_email: e.target.value.split("|")[2],
        client_phone: e.target.value.split("|")[3],
        client_id: e.target.value.split("|")[0],
      })
    }
    if (e.target.name === 'service_id') {
      console.log(e.target.value.split("|")[1])
      this.setState({
        service_id: e.target.value.split("|")[0],
        service_price: e.target.value.split("|")[1],
        with_coupon_price: e.target.value.split("|")[1],
        coupon_amount: 0,
        coupon_code: '',
        msg: '',
      })

    }
    if (e.target.name === 'coupon_code') {
      const data = {
        service_id: this.state.service_id,
        coupon_code: e.target.value,
      }

      authpost(`/api/applycouponcode`, data)
        .then((response) => {

          console.log(response)
          if (response.data.success === true) {
            this.setState({
              coupon_amount: response.data.couponvalue,
              with_coupon_price: (this.state.service_price) - (response.data.couponvalue),
              msg: response.data.msg,
              classd: 'success'
            })
          } else {
            this.setState({
              coupon_amount: 0,
              with_coupon_price: this.state.service_price,
              msg: response.data.msg,
              classd: 'danger'
            })
          }

        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  componentDidMount() {
    authget(`/api/showService`)
      .then((response) => {
        this.setState({
          service: response.data.service
        })
      })
      .catch(function (error) {
        console.log(error);
      });

    authget(`/api/viewCity`)
      .then((response) => {
        console.log(response.data.city)
        const da = response.data.city.filter(gs => gs.city_status === 1)
        this.setState({
          city: da
        })
      })
      .catch(function (error) {
        console.log(error);
      });

    authget(`/api/showClient`)
      .then((response) => {
        console.log(response.data.data.get_user)
        this.setState({
          client: response.data.data.get_user
        })

      })
      .catch(function (error) {
        console.log(error);
      });

  }
  render() {
    return (
      <div>
        <div className="page-header mt-0 p-3">
          <h3 className="mb-sm-0">Booking Forms</h3>
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <Link to="/">
                <i className="fe fe-home"></i>
              </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Booking Forms
            </li>
          </ol>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card shadow">
              <div className="card-header">
                <h2 className="mb-0">Booking forms</h2>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">Service Name</label>
                      <select name="service_id" className="form-control" onChange={this.handleChange}>
                        <option value="">--select service--</option>
                        {this.state.service.map((obj, i) =>
                          <option value={`${obj.id}|${obj.service_price}`}>{obj.service_name}</option>
                        )}
                      </select>
                      {this.validator.message(
                        "service_id",
                        this.state.service_id,
                        "required",
                        { className: "text-danger" }
                      )}

                    </div>

                    <div className="form-group">
                      <label className="form-label">Select Client </label>
                      <select name="client_id" className="form-control" onChange={this.handleChange}>
                        <option value="">--Select Client --</option>
                        {this.state.client.map((obj, i) =>
                          <option value={`${obj.id}|${obj.name}|${obj.email}|${obj.phone}`}>{obj.name} ({obj.phone})</option>
                        )}
                      </select>
                      {this.validator.message(
                        "client_id",
                        this.state.client_id,
                        "required",
                        { className: "text-danger" }
                      )}

                    </div>

                    <div className="form-group">
                      <label className="form-label">Client Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="client_name"
                        placeholder="Enter Client Name"
                        onChange={this.handleChange}
                        value={this.state.client_name}
                      />
                      {this.validator.message(
                        "client_name",
                        this.state.client_name,
                        "required",
                        { className: "text-danger" }
                      )}
                    </div>

                    <div className="form-group">
                      <label className="form-label">Contact No</label>
                      <input
                        type="text"
                        className="form-control"
                        name="client_phone"
                        placeholder="Enter Phone No"
                        onChange={this.handleChange}
                        value={this.state.client_phone}
                      />
                      {this.validator.message(
                        "client_phone",
                        this.state.client_phone,
                        "required",
                        { className: "text-danger" }
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label"> Select City</label>
                      <select name="city_id" className="form-control" onChange={this.handleChange}>
                        <option value="">--select City--</option>
                        {this.state.city.map((obj, i) =>
                          <option value={obj.id}>{obj.city_name}</option>
                        )}
                      </select>
                      {this.validator.message(
                        "city_id",
                        this.state.city_id,
                        "required",
                        { className: "text-danger" }
                      )}

                    </div>

                    <div className="form-group">
                      <label className="form-label">Enter Email</label>
                      <input
                        type="text"
                        className="form-control "
                        name="client_email"
                        placeholder="Valid Email.."
                        onChange={this.handleChange}
                        value={this.state.client_email}
                      />
                      {this.validator.message(
                        "client_email",
                        this.state.client_email,
                        "required",
                        { className: "text-danger" }
                      )}
                    </div>

                    <div className="form-group">
                      <label className="form-label">Enter Address</label>
                      <input
                        type="text"
                        className="form-control"
                        name="client_address"
                        placeholder="Enter Address"
                        onChange={this.handleChange}
                        value={this.state.client_address}
                      />
                      {this.validator.message(
                        "client_address",
                        this.state.client_address,
                        "required",
                        { className: "text-danger" }
                      )}
                    </div>

                    <div className="form-group m-0">
                      <label className="form-label">Apply Coupon Code</label>
                      <input
                        type="text"
                        className="form-control"
                        name="coupon_code"
                        placeholder="Enter Coupon Code"
                        onChange={this.handleChange}
                        value={this.state.coupon_code}
                        autoComplete="off"
                      />
                      <div className={`text-${this.state.classd}`} ><b>{this.state.msg}</b></div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-0">
                      <label className="form-label">Payable Amount</label>
                      <input
                        type="number"
                        className="form-control"
                        name="with_coupon_price"
                        placeholder="Enter Coupon Price"
                        onChange={this.handleChange}
                        value={this.state.with_coupon_price}
                        autoComplete="off"
                      />
                      {this.validator.message(
                        "with_coupon_price",
                        this.state.with_coupon_price,
                        "required",
                        { className: "text-danger" }
                      )}
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group mb-0">
                      <label className="form-label">Message (Optional)</label>
                      <textarea
                        className="form-control"
                        name="client_msg"
                        rows="4"
                        placeholder="text here.."
                        onChange={this.handleChange}
                        value={this.state.client_msg}
                      ></textarea>

                    </div>
                  </div>

                  <div className="col-md-12">
                    <br />
                    <br />
                    <button
                      type="button"
                      onClick={this.handleSubmit}
                      disabled={this.state.loading}
                      className="btn btn-success"
                      style={{ float: "right" }}
                    >
                      {this.state.loading ? "Loading.." : "Submit"}
                    </button>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

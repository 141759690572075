import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, withRouter } from 'react-router-dom'

// import {UserContext} from './../../content/App'
import { authput, authget, post, authformpost, authdelete } from '../../utils/service'

export default function Partner() {
    const [partner, setPartner] = useState([])
    useEffect(() => {
        window.scrollTo(0, 0);
        authget(`/api/showpartner`)
            .then((response) => {
                // console.log(response.data.partner)
                setPartner(response.data.partner)
            })
            .catch(function (error) {
                console.log(error);
            });

    }, [])
    return (
        <div>

            <div className="page-header mt-0 p-3">
                <h3 className="mb-sm-0">View Freelancer</h3>
                <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                        <Link to="/"><i className="fe fe-home"></i></Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">View Freelancer</li>
                </ol>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card shadow">
                        <div className="card-header">
                            <h2 className="mb-0">View Freelancer</h2>
                        </div>
                        <div className="card-body">
                            <MaterialTable
                                title="Freelancer list"
                                columns={[
                                    { title: 'Name', field: 'name' },
                                    { title: 'Email', field: 'email' },
                                    { title: 'Contact No', field: 'phone', type: 'numeric' },
                                    { title: 'Description', field: 'description' },



                                ]}
                                data={partner}
                                options={{
                                    actionsColumnIndex: -1
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

import React, { useState, useEffect, useContext } from "react";
import MaterialTable from "material-table";
import { toast } from "react-toastify";
import axios from "axios";
import { checkAuth } from "../../utils/auth";
import {
  authput,
  authget,
  post,
  authformpost,
  authdelete,
} from "../../utils/service";
import "react-toastify/dist/ReactToastify.css";
import { Link, withRouter } from "react-router-dom";
import * as CONSTANT from "../../utils/constant";
var urlCrypt = require("url-crypt")(
  '~{ry*I)==yU/]9<7DPk!Hj"R#:-/Z7(hTBnlRS=4CXF'
);

export default function ViewCategoryLibrary(props) {
  const [allCategory1, setCategory1] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
    authget(`/api/showLCategory`)
      .then((response) => {
        const da = response.data.allLCategory;
        setCategory1(da);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const editCat = (laibary_cat_id, laibrary_cat_name, type) => {
    const data = {
      laibary_cat_id: laibary_cat_id,
      laibrary_cat_name: laibrary_cat_name,
      type: type,
    };
    var base64 = urlCrypt.cryptObj(data);
    props.history.push(`/editcategorylibrary?data=${base64}`);
  };
  const deleteCat = (laibary_cat_id) => {
    authput(`/api/statusLCategory?laibary_cat_id=${laibary_cat_id}`)
      .then((data) => {
        toast.dark(data.data.msg, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 3000,
        });
        const da = data.data.cat;
        setCategory1(da);
      })
      .catch((err) => {
        console.log(err);
        // this.setState({ loading: false });
      });
  };
  console.log(allCategory1);
  return (
    <div>
      <div className="page-header mt-0 p-3">
        <h3 className="mb-sm-0">View Library Category</h3>
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item">
            <a href="#">
              <i className="fe fe-home"></i>
            </a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            View Library Category
          </li>
        </ol>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="card shadow">
            <div className="card-body">
              <div className="card-header">
                <Link
                  to="addcategorylaibrary"
                  className="btn btn-success btn-sm"
                  style={{ float: "right" }}
                >
                  {" "}
                  Add Library Category
                </Link>
                <br />{" "}
              </div>

              <MaterialTable
                title="View Library Category"
                columns={[
                  { title: "Category Name", field: "laibrary_cat_name" },
                  { title: "Type", field: "type" },
                  {
                    title: "Status",
                    field: "laibrary_cat_status",
                    render: (row) => (
                      <div
                        className={
                          row.laibrary_cat_status ? "active1" : "deactive1"
                        }
                      >
                        {row.laibrary_cat_status === 1 ? "Active" : "Deactive"}
                      </div>
                    ),
                  },
                ]}
                data={allCategory1}
                actions={[
                  (rowData) => ({
                    icon: rowData.laibrary_cat_status == 1 ? "D" : "A",
                    tooltip:
                      rowData.laibrary_cat_status == 1 ? "Disactive" : "Active",
                    onClick: (event, rowData) =>
                      deleteCat(rowData.laibary_cat_id),
                  }),
                  (rowData) => ({
                    icon: "edit",
                    tooltip: "Edit Category",
                    onClick: (event, rowData) =>
                      editCat(
                        rowData.laibary_cat_id,
                        rowData.laibrary_cat_name,
                        rowData.type
                      ),
                    disabled: rowData.categoryname < 2000,
                  }),
                ]}
                options={{
                  actionsColumnIndex: -1,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

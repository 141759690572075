import React, { Component } from 'react'
import axios from 'axios';
import { checkAuth } from '../../utils/auth';
import { post, authformpost } from '../../utils/service'
import SimpleReactValidator from 'simple-react-validator';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { imageValid } from '../../utils/imageValid';
import { Link, withRouter } from 'react-router-dom'
export default class AddCategoryLibrary extends Component {
    constructor(props) {
        super(props)
        this.state = {
            laibrary_cat_name: '',
            type: '',
            loading: false,
        }
        this.validator = new SimpleReactValidator();
    }
    handleChange = e => {
        e.preventDefault();
        this.setState({ [e.target.name]: e.target.value });
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ loading: true });
        var formdata = new FormData(e.target);
        if (this.validator.allValid()) {
            authformpost('/api/addLCategory', formdata)
                .then((data) => {
                    toast.dark(data.data.msg,
                        { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3000 })
                    this.props.history.push('/viewcategorylaibrary');
                }).catch((err) => {
                    this.setState({ loading: false });
                })
        } else {
            this.validator.showMessages();
            this.forceUpdate();
            this.setState({ loading: false });
        }
    }
    render() {
        return (
            <div>

                <div className="page-header mt-0 p-3">
                    <h3 className="mb-sm-0">Add Laibrary Category</h3>
                    <ol className="breadcrumb mb-0">
                        <li className="breadcrumb-item">
                            <Link to="/"><i className="fe fe-home"></i></Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">Add Laibrary Category</li>
                    </ol>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card shadow">
                            <div className="card-header">
                                <h2 className="mb-0">Category Add</h2>
                            </div>
                            <form onSubmit={this.handleSubmit}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-4"></div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                Library Category Name:
                                                    <input type="text" className="form-control" id="laibrary_cat_name" name="laibrary_cat_name" placeholder="Enter Library Category Name" onChange={this.handleChange} value={this.state.laibrary_cat_name} />
                                                {this.validator.message('laibrary_cat_name', this.state.laibrary_cat_name, 'required', { className: 'text-danger' })}

                                            </div>
                                            <div className="form-group">
                                                Category Type:
                                                <select name="type" id="type" className="form-control" onChange={this.handleChange} value={this.state.type}>
                                                    <option value="" disabled selected> Select Option</option>
                                                    <option value="Video">Video</option>
                                                    <option value="Video">Document</option>
                                                </select>
                                                {this.validator.message('type', this.state.type, 'required', { className: 'text-danger' })}

                                            </div>
                                            <div className="form-group">
                                                <center><button className="btn btn-success" disabled={this.state.loading}>{this.state.loading ? 'Loading...' : 'Submit'}</button></center>
                                            </div>
                                        </div>
                                        <div className="col-md-4"></div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

import React, {
  Component,
  Suspense,
  lazy,
  useEffect,
  createContext,
  useReducer,
  useContext,
} from "react";
import "./App.css";
import {
  withRouter,
  Route,
  Switch,
  Redirect,
  useHistory,
  BrowserRouter as Router,
} from "react-router-dom";
import Header from "./containers/HeaderContainer";
import Siderbar from "./containers/SidebarContainer";
import Footer from "./containers/FooterContainer";
import Home from "./containers/MainContainer";
import ViewCategory from "./containers/ViewCategoryContainer";
import AddCategory from "./containers/AddCategoryContainer";
import ViewSubCategory from "./containers/ViewSubCategoryContainer";
import AddSubCategory from "./containers/AddSubCategoryContainer";
import ViewService from "./containers/ViewserviceContainer";
import AddService from "./containers/AddserviceContainer";
import ViewUser from "./containers/ViewuserContainer";
import ViewConsaltentComponent from "./containers/ViewConsaltentComponent";
import ViewBannerComponent from "./containers/ViewBannerComponent";
import AddBannerComponent from "./containers/AddBannerComponent";
import ViewFreelancerComponent from "./containers/ViewFreelancerComponent";
import AddInqueryComponent from "./containers/AddInqueryComponent";
import ViewInqueryComponent from "./containers/ViewInqueryComponent";
import Add_city from "./components/City_component/Add_city";
import View_city from "./components/City_component/View_city";
import Default404 from "./components/404/Default404";
import Login from "./components/Auth/Login";
import { checkAuth } from "./utils/auth";
import Edit_city from "./components/City_component/Edit_city";
import ViewCityComponent from "./containers/ViewCityComponent";
import EditCategory from "./components/Category_components/EditCategory";
import Projecttrack from "./components/ProjectTrack_component/Projecttrack";
import Editsubcategory from "./components/Subcategory_components/Editsubcategory";
import Editservice from "./components/Service_components/Editservice";
import Viewservicedetails from "./components/Service_components/Viewservicedetails";
import ViewEnqueryDetailsComponent from "./containers/ViewEnqueryDetailsComponent";
import Addvideo from "./components/Video_component/Addvideo";
import Viewvideo from "./components/Video_component/Viewvideo";
import Add_complete_service from "./components/Complete_service/Add_complete_service";
import View_complete_service from "./components/Complete_service/View_complete_service";
import Add_coupon from "./components/Coupon/Add_coupon";
import View_Coupon from "./components/Coupon/View_Coupon";
import Carear from "./components/Partner/Carear";
import Partner from "./components/Partner/Partner";
import Review from "./components/City_component/Review";
import AddCategoryLibrary from "./components/CategoryLibrary/AddCategoryLibrary";
import ViewCategoryLibrary from "./components/CategoryLibrary/ViewCategoryLibrary";
import EditCategoryLibrary from "./components/CategoryLibrary/EditCategoryLibrary";
import AddLibrary from "./components/Library/AddLibrary";
import ViewLibrary from "./components/Library/ViewLibrary";
import EditLibrary from "./components/Library/EditLibrary";
import CustomizeBooking from "./components/Inquiry_component/CustomizeBooking";
import Edit_Complete_service from "./components/Complete_service/Edit_Complete_service";
import Edit_Banner from "./components/Banner_component/Edit_Banner";
import Add_consaltent from "./components/Consultant_components/Add_consaltent";
import Offer from "./components/Offer/Offer";
import Addoffer from "./components/Offer/Addoffer";
import Payment from "./components/User_components/Payment";
import View_Payment from "./components/User_components/View_Payment";
import Add_freelancer from "./components/Freelancer/Add_freelancer";
import TaskAssign from "./components/Freelancer/TaskAssign";
import ViewTask from "./components/Freelancer/ViewTask";
import UpdateTask from "./components/Freelancer/UpdateTask";
import Track from "./components/Freelancer/Track";
import AddSCategory from "./components/SCategoryComponent/AddSCategory";
import ViewSCategory from "./components/SCategoryComponent/ViewSCategory";
import EditSCategory from "./components/SCategoryComponent/EditSCategory";

const Routing = (props) => {
  const history = useHistory();

  useEffect(() => {
    if (history.location.pathname.startsWith("/login")) {
      var element1 = document.getElementById("a");
      element1.classList.remove("app-content");
      var element2 = document.getElementById("b");
      element2.classList.remove("side-app");
      var element3 = document.getElementById("c");
      element3.classList.remove("main-content");
      var element4 = document.getElementById("d");
      element4.classList.remove("container-fluid");
      var element5 = document.getElementById("d");
      element5.classList.remove("pt-8");
    }
  }, []);
  return (
    <div className="app sidebar-mini rtl">
      {/* <div id="global-loader" ></div> */}
      <div className="page">
        <div className="page-main">
          <div className="app-sidebar__overlay" data-toggle="sidebar"></div>

          <Siderbar {...props} />
          <div id="a" className="app-content">
            <div id="b" className="side-app">
              <div id="c" className="main-content">
                <Header {...props} />
                <div id="d" className="container-fluid pt-8">
                  <Switch>
                    <Route path="/login" component={Login}></Route>
                    <PrivateRoute
                      exact
                      path="/"
                      component={Home}
                    ></PrivateRoute>
                    <PrivateRoute
                      path="/category"
                      component={ViewCategory}
                    ></PrivateRoute>
                    <PrivateRoute
                      path="/addcategory"
                      component={AddCategory}
                    ></PrivateRoute>
                    <PrivateRoute
                      path="/subcategory"
                      component={ViewSubCategory}
                    ></PrivateRoute>
                    <PrivateRoute
                      path="/addsubcategory"
                      component={AddSubCategory}
                    ></PrivateRoute>
                    <PrivateRoute
                      path="/service"
                      component={ViewService}
                    ></PrivateRoute>
                    <PrivateRoute
                      path="/addservice"
                      component={AddService}
                    ></PrivateRoute>
                    {/* <PrivateRoute path="/user" component={ViewUser} {...props}></PrivateRoute> */}
                    <PrivateRoute
                      path="/user"
                      component={Payment}
                    ></PrivateRoute>
                    <PrivateRoute
                      path="/viewconsaltent"
                      component={ViewConsaltentComponent}
                    ></PrivateRoute>
                    <PrivateRoute
                      path="/viewbanner"
                      component={ViewBannerComponent}
                    ></PrivateRoute>
                    <PrivateRoute
                      path="/addbanner"
                      component={AddBannerComponent}
                    ></PrivateRoute>
                    <PrivateRoute
                      path="/viewfreelancer"
                      component={ViewFreelancerComponent}
                    ></PrivateRoute>
                    <PrivateRoute
                      path="/addinquery"
                      component={AddInqueryComponent}
                    ></PrivateRoute>
                    <PrivateRoute
                      path="/viewinquery"
                      {...props}
                      component={ViewInqueryComponent}
                    ></PrivateRoute>
                    <PrivateRoute
                      path="/add_city"
                      component={Add_city}
                    ></PrivateRoute>
                    <PrivateRoute
                      path="/view_city"
                      component={ViewCityComponent}
                    ></PrivateRoute>
                    <PrivateRoute
                      path="/edit_city"
                      component={Edit_city}
                    ></PrivateRoute>
                    <PrivateRoute
                      path="/edit_category"
                      component={EditCategory}
                    ></PrivateRoute>
                    <PrivateRoute
                      path="/viewproject"
                      component={Projecttrack}
                    ></PrivateRoute>
                    <PrivateRoute
                      path="/editsubcategory"
                      component={Editsubcategory}
                    ></PrivateRoute>
                    <PrivateRoute
                      path="/editservice"
                      component={Editservice}
                    ></PrivateRoute>
                    <PrivateRoute
                      path="/viewservicedetails"
                      component={Viewservicedetails}
                    ></PrivateRoute>
                    <PrivateRoute
                      path="/viewenquerydetails"
                      component={ViewEnqueryDetailsComponent}
                    ></PrivateRoute>
                    <PrivateRoute
                      path="/addvideo"
                      component={Addvideo}
                    ></PrivateRoute>
                    <PrivateRoute
                      path="/viewvideo"
                      component={Viewvideo}
                    ></PrivateRoute>
                    <PrivateRoute
                      path="/add_complete_service"
                      component={Add_complete_service}
                    ></PrivateRoute>
                    <PrivateRoute
                      path="/view_complete_service"
                      component={View_complete_service}
                    ></PrivateRoute>
                    <PrivateRoute
                      path="/add_coupon"
                      component={Add_coupon}
                    ></PrivateRoute>
                    <PrivateRoute
                      path="/view_coupon"
                      component={View_Coupon}
                    ></PrivateRoute>
                    <PrivateRoute
                      path="/carear"
                      component={Carear}
                    ></PrivateRoute>
                    <PrivateRoute
                      path="/partner"
                      component={Partner}
                    ></PrivateRoute>
                    <PrivateRoute
                      path="/review"
                      component={Review}
                    ></PrivateRoute>
                    <PrivateRoute
                      path="/addcategorylaibrary"
                      component={AddCategoryLibrary}
                    ></PrivateRoute>
                    <PrivateRoute
                      path="/viewcategorylaibrary"
                      component={ViewCategoryLibrary}
                    ></PrivateRoute>
                    <PrivateRoute
                      path="/editcategorylibrary"
                      component={EditCategoryLibrary}
                    ></PrivateRoute>
                    <PrivateRoute
                      path="/addlibrary"
                      component={AddLibrary}
                    ></PrivateRoute>
                    <PrivateRoute
                      path="/viewLibrary"
                      component={ViewLibrary}
                    ></PrivateRoute>
                    <PrivateRoute
                      path="/editLibrary"
                      component={EditLibrary}
                    ></PrivateRoute>
                    <PrivateRoute
                      path="/customizebooking"
                      component={CustomizeBooking}
                    ></PrivateRoute>
                    <PrivateRoute
                      path="/edit_complete_service"
                      component={Edit_Complete_service}
                    ></PrivateRoute>
                    <PrivateRoute
                      path="/Edit_Banner"
                      component={Edit_Banner}
                    ></PrivateRoute>
                    <PrivateRoute
                      path="/add_consaltent"
                      component={Add_consaltent}
                    ></PrivateRoute>

                    <PrivateRoute
                      path="/offer"
                      component={Offer}
                    ></PrivateRoute>
                    <PrivateRoute
                      path="/add_offer"
                      component={Addoffer}
                    ></PrivateRoute>
                    <PrivateRoute
                      path="/payment_transaction"
                      component={View_Payment}
                    ></PrivateRoute>
                    <PrivateRoute
                      path="/Add_freelancer"
                      component={Add_freelancer}
                    ></PrivateRoute>
                    <PrivateRoute
                      path="/task_asign"
                      component={TaskAssign}
                    ></PrivateRoute>

                    <PrivateRoute
                      path="/task_asign"
                      component={TaskAssign}
                    ></PrivateRoute>
                    <PrivateRoute
                      path="/Viewtask"
                      component={ViewTask}
                    ></PrivateRoute>
                    <PrivateRoute
                      path="/update_task"
                      component={UpdateTask}
                    ></PrivateRoute>
                    <PrivateRoute
                      path="/track"
                      component={Track}
                    ></PrivateRoute>

                    <PrivateRoute
                      path="/addscategory"
                      component={AddSCategory}
                    ></PrivateRoute>

                    <PrivateRoute
                      path="/viewscategory"
                      component={ViewSCategory}
                    ></PrivateRoute>

                    <PrivateRoute
                      path="/editscategory"
                      component={EditSCategory}
                    ></PrivateRoute>

                    {/* <PrivateRoute path="/view_coupon" component={Add_coupon}></PrivateRoute> */}

                    <PrivateRoute component={Default404}></PrivateRoute>
                  </Switch>

                  <Footer {...props} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      checkAuth.isAuthenticated ? (
        <Component {...props} />
      ) : (
        <Redirect to="/login" />
      )
    }
  />
);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div>
        <Routing {...this.props} />
      </div>
    );
  }
}

export default withRouter(App);

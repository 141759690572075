import React,{ Component,Suspense,lazy} from 'react'
import {connect} from 'react-redux'
import {addToCart} from '../services/Actions/actions'
import Loding from "../components/Home_componets/Loding";

const View_freelancer=lazy(()=>import('../components/Freelancer/View_freelancer'))

function ViewFreelancerComponent() {
    return (
        <div>
            <Suspense   fallback={<div> <Loding/></div>}> 
                    <View_freelancer/>    
            </Suspense>  
        </div>
    )
}

const mapStateToProps=state=>({
    data:state.addItems
})
const mapDispatchToProps=dispatch=>({
    

})
export default connect(mapStateToProps,mapDispatchToProps)(ViewFreelancerComponent)
import React, { Component } from 'react'
import axios from 'axios';
import { checkAuth } from '../../utils/auth';
import { post, authformpost } from '../../utils/service'
import SimpleReactValidator from 'simple-react-validator';
import { toast } from 'react-toastify';
import { Link, withRouter } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css';
import { imageValid } from '../../utils/imageValid';

export default class Addoffer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            offer: '',
            valid_date: '',
            loading:false,
            type:""
        }
        this.validator = new SimpleReactValidator();
    }

    handleChange = e => {
        e.preventDefault();
        this.setState({ [e.target.name]: e.target.value });
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ loading: true });
        var formdata = new FormData(e.target);
        var formdata1 = [...formdata];
        if (this.validator.allValid()) {
            authformpost('/api/add_offer', formdata)
                .then((data) => {
                    toast.dark(data.data.msg,
                        { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3000 })
                    this.props.history.push('/offer');
                }).catch((err) => {
                    this.setState({ loading: false });
                })
        } else {
            this.validator.showMessages();
            this.forceUpdate();
            this.setState({ loading: false });
        }
    }
    render() {
        return (
            <div>
            <div>
                <div className="page-header mt-0 p-3">
                    <h3 className="mb-sm-0">Complete Service</h3>
                    <ol className="breadcrumb mb-0">
                        <li className="breadcrumb-item">
                            <Link to="/"><i className="fe fe-home"></i></Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">Complete Service</li>
                    </ol>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card shadow">
                            <div className="card-header">
                                <h2 className="mb-0">Complete Service Add</h2>
                            </div>
                            <form onSubmit={this.handleSubmit}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label className="form-label" >Offer</label>
                                                <select name="type" id="type" className="form-control" onChange={this.handleChange}>
                                                    <option value="" disabled selected>Select Type</option>
                                                    <option value="consultant">Consultant</option>
                                                    <option value="freelancer">Freelancer</option>
                                                </select>
                                                {this.validator.message('type', this.state.type, 'required', { className: 'text-danger' })}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Offer</label>
                                                <input type="text" className="form-control" name="offer" id="offer" placeholder="Offer" onChange={this.handleChange} value={this.state.offer} />
                                                {this.validator.message('offer', this.state.offer, 'required', { className: 'text-danger' })}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group mb-0">
                                                <label className="form-label">Validity Date</label>
                                                <input type="date" className="form-control" name="valid_date" id="valid_date" placeholder="Validaty Date" onChange={this.handleChange} value={this.state.valid_date} />
                                                {this.validator.message('valid_date', this.state.valid_date, 'required', { className: 'text-danger' })}

                                            </div>
                                        </div>
                                       
                                        <div className="col-md-12">
                                            <div className="form-group m"><br>
                                            </br>
                                                <center><button className="btn btn-success">Submit</button></center>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    
        )
    }
}

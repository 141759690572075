import React, { Component,Suspense,lazy} from "react";
import {connect} from 'react-redux'
import {addToCart,removeToCart} from '../services/Actions/actions'

import Loding from "../components/Home_componets/Loding";

const MainHome=lazy(()=>import('../components/Home_componets/MainHome'))

function MainContainer() {
    return (
        
        <div>
                <Suspense   fallback={<div> <Loding/></div>}>
                    
              <MainHome/>
                   
                 </Suspense>  
        </div>
    )
}

const mapStateToProps=state=>({
    wdata:state.userWallet
})
const mapDispatchToProps=dispatch=>({
    addToCartHandler:data=>dispatch(addToCart(data)),
    removeToCartHandler:data=>dispatch(removeToCart(data))

})
export default connect(mapStateToProps,mapDispatchToProps)(MainContainer)
import React, { useState, useEffect, useContext } from 'react'
import MaterialTable from 'material-table'
import { toast } from 'react-toastify';
import axios from 'axios';
import { checkAuth } from '../../utils/auth';
import { authput, authget, post, authformpost, authdelete } from '../../utils/service'
import * as CONSTANT from '../../utils/constant';
import 'react-toastify/dist/ReactToastify.css';
import { Link, withRouter } from 'react-router-dom'
toast.configure();
export default function Viewvideo() {
    const [allVideo, setVideo] = useState([])
    useEffect(() => {
        window.scrollTo(0, 0);
        authget(`/api/viewVideo`)
            .then((response) => {
                console.log(response.data);
                setVideo(response.data.video)
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [])
    const deleteVideo = (id) => {
        authdelete(`/api/deleteVideo?id=${id}`)
            .then((response) => {
                setVideo(response.data.video)
                toast.dark('Video Delete Successful',
                    { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3000 })
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    return (
        <div>
            <div className="page-header mt-0 p-3">
                <h3 className="mb-sm-0">Video View</h3>
                <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                        <Link to="/"><i className="fe fe-home"></i></Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Video View</li>
                </ol>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card shadow">
                        <div className="card-header">
                            <h2 className="mb-0">Video View</h2>
                        </div>
                        <div className="card-body">
                            <MaterialTable
                                title="Video View"
                                columns={[
                                    { title: 'Video Name', field: 'video_name' },
                                    { title: 'Youtube Link', field: 'video_file' },
                                    // { title: 'Thumbnail', field: 'video_des', render: rowData => <a href={`${CONSTANT.URL}/storage/${rowData.video_thumblane}`} target="_blank"><img src={`${CONSTANT.URL}/storage/${rowData.video_thumblane}`} style={{ width: 50, }} /></a> },
                                ]}
                                data={allVideo}
                                actions={[
                                    rowData => ({
                                        icon: 'delete',
                                        tooltip: 'Delete Video',
                                        onClick: (event, rowData) => deleteVideo(rowData.id),
                                        disabled: rowData.birthYear < 2000
                                    })
                                ]}
                                options={{
                                    actionsColumnIndex: -1
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

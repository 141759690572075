import React, { Component } from 'react'
import axios from 'axios';
import { checkAuth } from '../../utils/auth';
import { post, authformpost } from '../../utils/service'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import queryString from 'query-string';
import { imageValid } from '../../utils/imageValid';
import * as CONSTANT from '../../utils/constant';
import { Link, withRouter } from 'react-router-dom'
var urlCrypt = require('url-crypt')('~{ry*I)==yU/]9<7DPk!Hj"R#:-/Z7(hTBnlRS=4CXF');
export default class EditCategory extends Component {
    constructor(props) {
        super(props)
        let param = this.props.location.search;
        const parsed = queryString.parse(param);
        var backAgain = urlCrypt.decryptObj(parsed.data);
        console.log(backAgain);
        this.state = {
            cat_details: backAgain,
            cat_name:backAgain.cat_name,
            cat_role:backAgain.cat_role,
            cat_icon:'',
            loading: false,
        }
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ loading: true });
        var formdata = new FormData(e.target);
        authformpost('/api/updateCategory', formdata)
            .then((data) => {
                toast.dark(data.data.msg,
                    { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3000 })
                this.props.history.push('/category');
                this.setState({ loading: false });

            }).catch((err) => {
                this.setState({ loading: false });
            })
    }
    handleChange = e => {
        e.preventDefault();
        this.setState({ [e.target.name]: e.target.value });
    }

    render() {
        return (

            <div>
                <div className="page-header mt-0 p-3">
                    <h3 className="mb-sm-0">Menu Edit</h3>
                    <ol className="breadcrumb mb-0">
                        <li className="breadcrumb-item">
                            <Link to="/"><i className="fe fe-home"></i></Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">Menu Edit</li>
                    </ol>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <div className="card shadow">
                            <div className="card-header">
                                <h2 className="mb-0">Menu Edit</h2>
                            </div>
                            <form onSubmit={this.handleSubmit}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-4"></div>
                                        <div className="col-md-4">
                                            <input type="hidden" name="cat_id" value={this.state.cat_details.cat_id}></input>
                                            <div className="form-group">
                                                Menu Name:
                                                    <input type="text" className="form-control" id="cat_name" name="cat_name" placeholder="Enter Category Name" onChange={this.handleChange} value={this.state.cat_name} required />
                                            </div>
                                            <div className="form-group">
                                                  Menu Role:
                                                    <select name="cat_role" id="cat_role" className="form-control" onChange={this.handleChange} required>
                                                        <option value="1">-- Select Option --</option>
                                                        <option value="1" selected={this.state.cat_role == 1?true:false}>Have Submenu</option>
                                                        <option value="2" selected={this.state.cat_role == 2?true:false}>Do Not Have Submenu</option>
                                                    </select>
                                                
                                            

                                            </div>
                                            <div className="form-group">
                                                Menu Icon:(178X74) <img src={`${CONSTANT.URL}/storage/${this.state.cat_details.cat_icon}`} style={{ width: 30, }}></img>
                                                <input type="file" className="form-control" name="cat_icon" id="cat_icon" onChange={(e) => imageValid(e)} onChange={this.handleChange} value={this.state.cat_icon} />

                                            </div>
                                            <div className="form-group">
                                                <center><button className="btn btn-success" disabled={this.state.loading}>{this.state.loading ? 'Loading...' : 'Submit'}</button></center>
                                            </div>
                                        </div>
                                        <div className="col-md-4"></div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

import React, { Component } from "react";
import Track from './Track'
export default class Projecttrack extends Component {
  render() {
    return (
      <div>
        <div className="page-header mt-0 p-3">
          <h3 className="mb-sm-0">View Project Track</h3>
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <a href="#">
                <i className="fe fe-home"></i>
              </a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
            View Project Track
            </li>
          </ol>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card shadow">
              <div className="card-header">
                <h2 className="mb-0">View Project Track</h2>
              </div>
              <div className="card-body">
                <Track />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

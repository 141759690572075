import React, { useState, useEffect, useContext } from "react";
import MaterialTable from "material-table";
import { toast } from "react-toastify";
import axios from "axios";
import { checkAuth } from "../../utils/auth";
import {
  authput,
  authget,
  post,
  authformpost,
  authdelete,
  authpost,
} from "../../utils/service";
import "react-toastify/dist/ReactToastify.css";
import { Link, withRouter } from "react-router-dom";

toast.configure();
function ViewSCategory(props) {
  const [allCity, setCity] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
    authget(`/api/ViewSpCat`)
      .then((response) => {
        // console.log(response.data);
        setCity(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
  const editCityname = (id) => {
    props.history.push(`/editscategory?id=${id}`);
  };
  const deleteCity = (id) => {
    authpost(`/api/ViewSpCatConsaltentStatusChenge?id=${id}`)
      .then((data) => {
        toast.dark(data.data.msg, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 3000,
        });
        setCity(data.data.data);
      })
      .catch((err) => {
        console.log(err);
        // this.setState({ loading: false });
      });
  };

  return (
    <div>
      <div className="page-header mt-0 p-3">
        <h3 className="mb-sm-0">View service Provider Category</h3>
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item">
            <Link to="/">
              <i className="fe fe-home"></i>
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            View service Provider Category
          </li>
        </ol>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="card shadow">
            <div className="card-header">
              <h2 className="mb-0">View service Provider </h2>
              <Link
                to="/addscategory"
                className="btn btn-success btn-sm"
                style={{ float: "right" }}
              >
                {" "}
                Add service Provider
              </Link>
            </div>
            <div className="">
              <MaterialTable
                title=""
                columns={[
                  { title: " Name", field: "s_category_name" },
                  {
                    title: "Status",
                    field: "S_c_status",
                    render: (row) => (
                      <div
                        className={
                          row.S_c_status === "YES" ? "active1" : "deactive1"
                        }
                      >
                        {row.S_c_status === "YES" ? "Active" : "Deactive"}
                      </div>
                    ),
                  },
                ]}
                data={allCity}
                actions={[
                  (rowData) => ({
                    icon: rowData.S_c_status === "YES" ? "D" : "A",
                    tooltip:
                      rowData.S_c_status === "YES" ? "Disactive" : "Active",
                    onClick: (event, rowData) => deleteCity(rowData.id),
                    disabled: rowData.birthYear < 2000,
                  }),
                  (rowData) => ({
                    icon: "edit",
                    tooltip: "Edit Name",
                    onClick: (event, rowData) => editCityname(rowData.id),
                    disabled: rowData.birthYear < 2000,
                  }),
                ]}
                options={{
                  actionsColumnIndex: -1,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default withRouter(ViewSCategory);

import React,{Component,Suspense,lazy} from 'react'
import {connect} from 'react-redux'
import {addToCart} from '../services/Actions/actions'
import Loding from "../components/Home_componets/Loding";
const ViewSubCategory=lazy(()=>import('../components/Subcategory_components/Viewsubcategory'))


function ViewSubCategoryContainer(props) {
    return (
        <div>
             <Suspense fallback={<div> <Loding/></div>}> 
                    <ViewSubCategory {...props}/>    
             </Suspense> 
            
        </div>
    )
}


const mapStateToProps=state=>({
    data:state.addItems
})
const mapDispatchToProps=dispatch=>({
    

})
export default connect(mapStateToProps,mapDispatchToProps)(ViewSubCategoryContainer)
import React, { useState, useEffect, useContext } from "react";
import MaterialTable from "material-table";
import { toast } from "react-toastify";
import axios from "axios";
import { checkAuth } from "../../utils/auth";
import {
  authput,
  authget,
  post,
  authformpost,
  authdelete,
} from "../../utils/service";
import * as CONSTANT from "../../utils/constant";
import "react-toastify/dist/ReactToastify.css";
import { Link, withRouter } from "react-router-dom";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import queryString from "query-string";

import { Button } from "@material-ui/core";
// var urlCrypt = require('url-crypt')('~{ry*I)==yU/]9<7DPk!Hj"R#:-/Z7(hTBnlRS=4CXF');
toast.configure();
export default function Track(props) {
  let param = props.location.search;
  const parsed = queryString.parse(param);

  const [track, setTrack] = useState([]);
  const [task_id, setTask_id] = useState(parsed.id);

  useEffect(() => {
    window.scrollTo(0, 0);
    abc();
  }, []);

  const abc = () => {
    authget(`/api/ViewCompleteTask?id=${task_id}`)
      .then((response) => {
        console.log(response?.data);
        setTrack(response?.data?.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //    const deleteSubmit = (id) => {
  //             authget(`/api/offer_delete?id=${id}`)
  //                 .then((data) => {
  //                     if(data.data.status == true){
  //                         toast.dark(data.data.msg,
  //                             { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3000 })
  //                           abc();
  //                     }else{
  //                         toast.dark(data.data.msg,
  //                             { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3000 })
  //                     }
  //                 }).catch((err) => {
  //                     // this.setState({ loading: false });
  //                 })
  //     }

  return (
    <div>
      <div className="page-header mt-0 p-3">
        <h3 className="mb-sm-0">Track</h3>
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item">
            <Link to="/">
              <i className="fe fe-home"></i>
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Track
          </li>
        </ol>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="card shadow">
            <div className="card-header">
              <h2 className="mb-0">
                Track
                {/* <Link to="add_offer" className="btn btn-success btn-sm float-right">Add Offer</Link> */}
              </h2>
            </div>
            <div className="card-body">
              <MaterialTable
                title="Track"
                columns={[
                  { title: "Description", field: "description" },
                  {
                    title: "Image",
                    field: "complete_image",

                    render: (rowData) => (
                      <a
                        href={`${CONSTANT.URL}/storage/${rowData.complete_image}`}
                        target="_blank"
                        attributes-list
                        download
                      >
                        {" "}
                        Download
                      </a>
                    ),
                  },
                ]}
                data={track}
                // actions={[
                //     rowData => ({
                //         icon: 'delete',
                //         tooltip: 'Delete Complete Service',
                //         onClick: (event, rowData) => {
                //             deleteSubmit(rowData.id)
                //         },
                //     }),
                // ]}
                options={{
                  actionsColumnIndex: -1,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

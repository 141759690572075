import React, { useState, useEffect, useContext } from 'react';
import MaterialTable from 'material-table'
import { toast } from 'react-toastify';
import axios from 'axios';
import { checkAuth } from '../../utils/auth';
import { authput, authget, post, authformpost, authdelete } from '../../utils/service'
import 'react-toastify/dist/ReactToastify.css';
import { Link, withRouter } from 'react-router-dom'
import * as CONSTANT from '../../utils/constant';
import { Modal } from 'react-responsive-modal';
import queryString from 'query-string';
var urlCrypt = require('url-crypt')('~{ry*I)==yU/]9<7DPk!Hj"R#:-/Z7(hTBnlRS=4CXF');
toast.configure();
export default function Viewservicedetails(props) {
    let param = props.location.search;
    const parsed = queryString.parse(param);
    var backAgain = urlCrypt.decryptObj(parsed.data);
    const deleteImage = (service_image_id) => {
        authdelete(`/api/serviceimageDelete?service_image_id=${service_image_id}`)
            .then((res) => {
                window.location.reload();

            })
            .catch((err) => {
                console.log(err);
            });
    }
    const addImage = (e) => {
        e.preventDefault();
        var formdata = new FormData(e.target);
        console.log(...formdata);
        authformpost(`/api/serviceimageAdd`, formdata)
            .then((res) => {
                window.location.reload();
            })
            .catch((err) => {
                console.log(err);
            });
    }
    const [image, setImage] = useState([])
    const [feature, setFeature] = useState([])


    useEffect(() => {
        window.scrollTo(0, 0);
        authget(`/api/showIndivisualService?service_id=${backAgain.service_id}`)
            .then((response) => {
                setImage(response.data.service.get_serviceimage)
                setFeature(response.data.service.get_servicefeature)
            })
            .catch(function (error) {
                console.log(error);
            });

    }, [])
    return (
        <div>
            <div className="card shadow">
                <div className="card-header">
                    <h2 className="mb-0">Service Details</h2>
                </div>
                <div className="card-body">
                    <div className="nav-wrapper">
                        <ul className="nav nav-pills nav-fill flex-column flex-md-row" id="tabs-icons-text" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link mb-sm-3 mb-md-0 active border" id="tabs-icons-text-1-tab" data-toggle="tab" href="#tabs-icons-text-1" role="tab" aria-controls="tabs-icons-text-1" aria-selected="true">
                                    Service Image 
                    </a>
                            </li>
                           
                            <li className="nav-item">
                                <a className="nav-link mb-sm-3 mb-md-0 border" id="tabs-icons-text-3-tab" data-toggle="tab" href="#tabs-icons-text-3" role="tab" aria-controls="tabs-icons-text-3" aria-selected="false">
                                    Service Details
                    </a>
                            </li>
                        </ul>
                    </div>
                    <div className="card shadow mb-0">
                        <div className="card-body">
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active" id="tabs-icons-text-1" role="tabpanel" aria-labelledby="tabs-icons-text-1-tab">
                                    <div className="gallery row">

                                        {
                                            image.map((obj, i) =>
                                                <div className="col-lg-4 hover15">
                                                    <button className="btn btn-danger btn-sm" onClick={() => deleteImage(obj.service_image_id)}><i className="fas fa-trash-alt"></i></button>
                                                    <div className="card shadow overflow-hidden">
                                                        <a href={`${CONSTANT.URL}/storage/${obj.service_icon}`} className="big"><img src={`${CONSTANT.URL}/storage/${obj.service_icon}`} alt="" title="Beautiful Image" /></a>
                                                    </div>
                                                </div>
                                            )
                                        }

                                    </div>
                                    <label> Service Image (270X216)</label>
                                    <form onSubmit={addImage}>
                                        <input type="hidden" value={backAgain.id} name="service_id" id="service_id"></input>
                                        <input type="file" classNmae="form-control" name="service_icon" id="service_icon" defaultValue="" required />
                                        <button type="submit" className="btn-success">Add New Image</button>
                                    </form>

                                </div>
                                <div className="tab-pane fade" id="tabs-icons-text-2" role="tabpanel" aria-labelledby="tabs-icons-text-2-tab">
                                    {feature.map((obj, i) =>
                                        <p className="description mb-0" key={i}>{obj.feature_name}</p>
                                    )}
                                </div>
                                <div className="tab-pane fade" id="tabs-icons-text-3" role="tabpanel" aria-labelledby="tabs-icons-text-3-tab">
                                    <p className="description mb-0">

                                        <>
                                            <div dangerouslySetInnerHTML={{ __html: backAgain.service_des }}></div>
                                        </>

                                    </p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}

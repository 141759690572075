import React, { useEffect, useState } from 'react'
import MaterialTable from 'material-table'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, withRouter } from 'react-router-dom'
import { authget } from '../../utils/service'
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import * as CONSTANT from '../../utils/constant';

function CustomizeBooking(props) {
    const [enquery, setEnquery] = useState([])
    const [open, setopen] = useState(false)
    const [data, setdata] = useState([])
    useEffect(() => {
        window.scrollTo(0, 0);
        authget(`/api/viewCustomize`)
            .then((response) => {
                // console.log(response.data)
                setEnquery(response.data.data)
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [])
    const onCloseModal = () => {
        setopen(false)
    }
    const showDetails = (id) => {
        props.history.push('/viewenquerydetails?id=' + id)
    }
    const viewDetails = (rowData) => {
        setopen(true)
        setdata(rowData)
        console.log(rowData)
    }

    return (
        <div>
            <div className="page-header mt-0 p-3">
                <h3 className="mb-sm-0">Custom Design Booking</h3>
                <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                        <Link to="/"><i className="fe fe-home"></i></Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Custom Design Booking</li>
                </ol>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card shadow">
                        <div className="card-header">
                            <h2 className="mb-0">Custom Design Booking</h2>
                        </div>
                        <div className="card-body">
                            <MaterialTable

                                columns={[
                                    { title: 'Order Id', field: 'order_id' },
                                    { title: 'City Name', field: 'city_id', render: rowData => <>{rowData.get_city_customize.city_name}</> },
                                    { title: 'Client Name', field: 'name' },
                                    { title: 'Contact No', field: 'phone' },
                                    { title: 'E-mail', field: 'email' },
                                    { title: 'Message', field: 'message' },
                                    { title: 'DOC', field: 'doc', render: rowData => rowData.doc !== null ? <a href={`${CONSTANT.URL}/storage/${rowData.doc}`} target="_blank"><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png" style={{ width: 50, }} /></a> : null },
                                ]}
                                data={enquery}
                                actions={[
                                    {
                                        icon: 'visibility',
                                        tooltip: 'Booking Details',
                                        onClick: (event, rowData) => viewDetails(rowData)

                                    },
                                    {
                                        icon: 'visibility',
                                        tooltip: 'Traking',
                                        onClick: (event, rowData) => showDetails(rowData.enquery_id)

                                    },

                                ]}
                                options={{
                                    actionsColumnIndex: -1
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default withRouter(CustomizeBooking)


import React ,{ Component,Suspense,lazy}from 'react'
import {connect} from 'react-redux'
import {addToCart} from '../services/Actions/actions'
import Loding from "../components/Home_componets/Loding";

const View_banner=lazy(()=>import('../components/Banner_component/View_banner'))


function ViewBannerComponent() {
    return (
        <div>
            <Suspense   fallback={<div> <Loding/></div>}> 
                    <View_banner/>    
            </Suspense>  
        </div>
    )
}


const mapStateToProps=state=>({
    data:state.addItems
})
const mapDispatchToProps=dispatch=>({
    

})
export default connect(mapStateToProps,mapDispatchToProps)(ViewBannerComponent)
import React, { Component } from 'react'
import axios from 'axios';
import { checkAuth } from '../../utils/auth';
import { post, authformpost } from '../../utils/service'
import SimpleReactValidator from 'simple-react-validator';
import { toast } from 'react-toastify';
import { Link, withRouter } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css';
import { imageValid } from '../../utils/imageValid';
// import {UserContext} from './../../content/App'
import queryString from 'query-string';
var urlCrypt = require('url-crypt')('~{ry*I)==yU/]9<7DPk!Hj"R#:-/Z7(hTBnlRS=4CXF');

export default class Edit_Complete_service extends Component {
    constructor(props) {
        super(props)
        let param = this.props.location.search;
        const parsed = queryString.parse(param);
        var backAgain = urlCrypt.decryptObj(parsed.data);
        console.log(backAgain);
        this.state = {
            complete_service_id:backAgain.complete_service_id,
            complete_service_name: backAgain.complete_service_name,
            complete_service_des: backAgain.complete_service_des,
            loading:false
        }
        this.validator = new SimpleReactValidator();
    }

    handleChange = e => {
        e.preventDefault();
        this.setState({ [e.target.name]: e.target.value });
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ loading: true });
        var formdata = new FormData(e.target);
        var formdata1 = [...formdata];
        if (this.validator.allValid()) {
            authformpost('/api/editcompleteservice', formdata)
                .then((data) => {
                    toast.dark(data.data.msg,
                        { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3000 })
                    this.props.history.push('/view_complete_service');
                }).catch((err) => {
                    this.setState({ loading: false });
                })
        } else {
            this.validator.showMessages();
            this.forceUpdate();
            this.setState({ loading: false });
        }
    }
   
    render() {
        return (
            <div>
                <div>
                    <div className="page-header mt-0 p-3">
                        <h3 className="mb-sm-0">Complete Service</h3>
                        <ol className="breadcrumb mb-0">
                            <li className="breadcrumb-item">
                                <Link to="/"><i className="fe fe-home"></i></Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Complete Service</li>
                        </ol>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card shadow">
                                <div className="card-header">
                                    <h2 className="mb-0">Complete Service Add</h2>
                                </div>
                                <form onSubmit={this.handleSubmit}>
                                    <input type="hidden" name="complete_service_id" value={this.state.complete_service_id}></input>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="form-label">Complete Service Name</label>
                                                    <input type="text" className="form-control" name="complete_service_name" id="complete_service_name" placeholder="Complete Service Name" onChange={this.handleChange} value={this.state.complete_service_name} />
                                                    {this.validator.message('complete_service_name', this.state.complete_service_name, 'required', { className: 'text-danger' })}
                                                </div>
                                            </div>
                                            
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="form-label">complete service description (Optional)</label>
                                                    <input type="text" className="form-control" name="complete_service_des" id="complete_service_des" placeholder="complete service description" onChange={this.handleChange} value={this.state.complete_service_des} />
                                                </div>

                                            </div>

                                            <div className="col-md-12">
                                                <div className="form-group m"><br>
                                                </br>
                                                    <center><button className="btn btn-success">Submit</button></center>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

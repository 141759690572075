import React, { Component } from 'react'
import axios from 'axios';
import { checkAuth } from '../../utils/auth';
import { post, authformpost, authget } from '../../utils/service'
import SimpleReactValidator from 'simple-react-validator';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { imageValid } from '../../utils/imageValid';
import { Link, withRouter } from 'react-router-dom'

export default class Add_city extends Component {
    constructor(props) {
        super(props)

        this.state = {
            cat_id: '',
            subcat_name: '',
            subcat_icon: '',
            cat: [],
            loading: false,
        }
        this.validator = new SimpleReactValidator();
    }
    componentDidMount() {
        authget(`/api/showCategory`)
            .then((response) => {
                this.setState({ cat: response.data.cat });
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    handleChange = e => {
        e.preventDefault();
        this.setState({ [e.target.name]: e.target.value });
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ loading: true });
        var formdata = new FormData(e.target);
        if (this.validator.allValid()) {
            authformpost('/api/addSubcategory', formdata)
                .then((data) => {
                    console.log(data);
                    toast.dark(data.data.msg,
                        { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3000 })
                    this.props.history.push('/subcategory');
                    this.setState({ loading: false });

                }).catch((err) => {
                    this.setState({ loading: false });
                })
        } else {
            this.validator.showMessages();
            this.forceUpdate();
            this.setState({ loading: false });
        }
    }


    render() {
        return (
            <div>
                <div className="page-header mt-0 p-3">
                    <h3 className="mb-sm-0">Add Submenu</h3>
                    <ol className="breadcrumb mb-0">
                        <li className="breadcrumb-item">
                            <Link to="/"><i className="fe fe-home"></i></Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">Add Submenu</li>
                    </ol>
                </div>
                <div className="row">
                    <div className="col-md-12">

                        <div className="card shadow">
                            <div className="card-header">
                                <h2 className="mb-0">Add Submenu</h2>
                            </div>
                            <form onSubmit={this.handleSubmit}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-4"></div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label className="form-label">Submenu Name</label>
                                                <input type="text" className="form-control" name="subcat_name" id="subcat_name" placeholder="submenu name" onChange={this.handleChange} value={this.state.subcat_name} />
                                                {this.validator.message('subcat_name', this.state.subcat_name, 'required', { className: 'text-danger' })}

                                            </div>
                                            <div className="form-group">
                                                <label className="form-label">Menu Name</label>
                                                <select name="cat_id" className="form-control" id="cat_id" onChange={this.handleChange} >
                                                    <option value="" disabled selected>-- Select Menu Name --</option>
                                                    {this.state.cat.map((object, i) =>
                                                        <option value={object.id}>{object.cat_name}</option>
                                                    )}
                                                </select>
                                                {this.validator.message('cat_id', this.state.cat_id, 'required', { className: 'text-danger' })}


                                            </div>
                                            <div className="form-group">
                                                <label className="form-label">SubMenu Icon (178X74)</label>
                                                <input type="file" className="form-control" name="subcat_icon" id="subcat_icon" onChange={(e) => imageValid(e)} onChange={this.handleChange} value={this.state.subcat_icon} />
                                                {this.validator.message('subcat_icon', this.state.subcat_icon, 'required', { className: 'text-danger' })}

                                            </div>
                                            <div className="form-group">
                                                <center><button className="btn btn-success" disabled={this.state.loading}>{this.state.loading ? 'Loading...' : 'Submit'}</button></center>
                                            </div>
                                        </div>
                                        <div className="col-md-4"></div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

import Header from '../components/layout_component/Sidebar'
import {connect} from 'react-redux';


 const mapStateToProps=state=>({
    data:state.addItems
})
const mapDispatchToProps=dispatch=>({
    

})
export default connect(mapStateToProps,mapDispatchToProps)(Header)
import React, { useState, useEffect, useContext } from 'react'
import MaterialTable from 'material-table'
import { toast } from 'react-toastify';
import axios from 'axios';
import { checkAuth } from '../../utils/auth';
import { authput, authget, post, authformpost, authdelete } from '../../utils/service'
import 'react-toastify/dist/ReactToastify.css';
import { Link, withRouter } from 'react-router-dom'

toast.configure();
function View_city(props) {
    const [allCity, setCity] = useState([])
    useEffect(() => {
        window.scrollTo(0, 0);
        authget(`/api/viewCity`)
            .then((response) => {
                console.log(response.data);
                setCity(response.data.city)
            })
            .catch(function (error) {
                console.log(error);
            });

    }, [])
    const editCityname = (city_id) => {
        props.history.push(`/edit_city?city_id=${city_id}`);
    }
    const deleteCity = (city_id) => {
        authput(`/api/activeCity?city_id=${city_id}`)
            .then((data) => {
                toast.dark(data.data.msg,
                    { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3000 })
                setCity(data.data.city)
            }).catch((err) => {
                console.log(err);
                // this.setState({ loading: false });
            })
    }

    return (
        <div>
            <div className="page-header mt-0 p-3">
                <h3 className="mb-sm-0">View City</h3>
                <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                        <Link to="/"><i className="fe fe-home"></i></Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">View City</li>
                </ol>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card shadow">
                        <div className="card-header">
                            <h2 className="mb-0">View City</h2>
                            <Link to="/add_city" className="btn btn-success btn-sm" style={{ "float": "right" }}> Add City</Link>

                        </div>
                        <div className="">
                            <MaterialTable
                                title="View City"

                                columns={[


                                    { title: 'City Name', field: 'city_name' },
                                    {
                                        title: "Status", field: 'status', render: (row) => <div className={row.city_status ? "active1" : "deactive1"}>
                                            {row.city_status === 1 ? "Active" : "Deactive"}
                                        </div>
                                    },
                                ]}
                                data={allCity}
                                actions={[
                                    rowData => ({
                                        icon: rowData.city_status === 1 ? 'D' : 'A',
                                        tooltip: rowData.city_status === 1 ? 'Disactive' : 'Active',
                                        onClick: (event, rowData) => deleteCity(rowData.city_id),
                                        disabled: rowData.birthYear < 2000
                                    }),
                                    rowData => ({
                                        icon: 'edit',
                                        tooltip: 'Edit City Name',
                                        onClick: (event, rowData) => editCityname(rowData.city_id),
                                        disabled: rowData.birthYear < 2000
                                    })
                                ]}
                                options={{
                                    actionsColumnIndex: -1
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default withRouter(View_city)
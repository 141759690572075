import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table'
import { toast } from 'react-toastify';
import { post, authpost, authformpost, authget } from '../../utils/service'
import 'react-toastify/dist/ReactToastify.css';
import { Link, withRouter } from 'react-router-dom'
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import * as CONSTANT from '../../utils/constant';
import queryString from 'query-string';
import moment from 'moment';

toast.configure();

export default function View_Payment(props) {
    let param = props.location.search;
    const parsed = queryString.parse(param);
    const [payment_detail, setPayment_details] = useState([])
    const [payment_value, setPayment_value] = useState('');
   
    const [user_id, setUser_id] = useState(parsed.id);
    useEffect(() => {
        window.scrollTo(0, 0);
        payment_details();
    }, []);

    const paymentSubmit = (event) => {
        event.preventDefault()
        const data = {
            user_id,
            payment_value,
        }
        authpost(`/api/payment_submit`, data)
            .then((response) => {
                console.log(response.data);
                payment_details();
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const payment_details = () => {
        authget(`/api/payment_list?user_id=${user_id}`)
            .then((response) => {
                console.log(response.data);
                setPayment_details(response.data.data)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    return (
        <div>
            
            <div className="page-header mt-0 p-3">
                <h3 className="mb-sm-0">Payment Details</h3>
                <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                        <Link to="/"><i className="fe fe-home"></i></Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Payment Details</li>
                </ol>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card shadow">
                        <div className="card-header">
                            <h2 className="mb-0">Payment Details</h2>
                        </div>
                        <div className="card-body">
                            <MaterialTable
                                title="Payment Details"
                                columns={[
                                    { title: 'Transaction amount', field: 'transaction_amount' },
                                    { title: 'Remaning Amount', field: 'remaning_amount' },
                                    { title: 'Purpose', field: 'purpose' },
                                    { title: 'Remark', field: 'remark' },
                                    { title: 'Date & Time', field: 'remark', render: rowData => <>{moment(rowData.created_at).format('MM/DD/YYYY, h:mm a')}</> },
 
                                ]}
                                data={payment_detail}
                               
                                options={{
                                    actionsColumnIndex: -1
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
       
        </div>
    )
}

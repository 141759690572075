import React, { Component } from 'react'
import axios from 'axios';
import { checkAuth } from '../../utils/auth';
import { post, authformpost } from '../../utils/service'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, withRouter } from 'react-router-dom'

// import {UserContext} from './../../content/App'
toast.configure();
export default class Add_city extends Component {
  constructor() {
    super();
    this.state = {
      shareholders: [{ name: '' }],
      loading: false,

    };
  }

  handleShareholderNameChange = (idx) => (evt) => {
    const newShareholders = this.state.shareholders.map((shareholder, sidx) => {
      if (idx !== sidx) return shareholder;
      return { ...shareholder, name: evt.target.value };
    });

    this.setState({ shareholders: newShareholders });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    var formdata = new FormData(e.target);
    var formdata1 = [...formdata];
    console.log(formdata1);
    authformpost('/api/addCity', formdata)
      .then((data) => {
        toast.dark(data.data.msg,
          { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3000 })
        this.props.history.push('/view_city');
      }).catch((err) => {
        this.setState({ loading: false });
      })
  }

  handleAddShareholder = () => {
    this.setState({ shareholders: this.state.shareholders.concat([{ name: '' }]) });
  }

  handleRemoveShareholder = (idx) => () => {
    this.setState({ shareholders: this.state.shareholders.filter((s, sidx) => idx !== sidx) });
  }

  render() {
    return (
      <div>
        <div className="page-header mt-0 p-3">
          <h3 className="mb-sm-0">Add City</h3>
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <Link to="/"><i className="fe fe-home"></i></Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">Add City</li>
          </ol>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card shadow">
              <div className="card-header">
                <h2 className="mb-0">City Add</h2>
              </div>
              <div className="card-body">
                <form onSubmit={this.handleSubmit}>
                  {this.state.shareholders.map((shareholder, idx) => (
                    <div className="shareholder">
                      <div className="row">
                        <div className="col-md-3"></div>
                        <div className="col-md-5">
                          <div className="form-group">
                            <input
                              type="text"
                              placeholder="Enter City Name"
                              name="city_name[]"
                              id="city_name"
                              value={shareholder.name} className="form-control"
                              onChange={this.handleShareholderNameChange(idx)}
                              required />
                          </div>
                        </div>
                        <div className="col-md-1">
                          <button type="button" onClick={this.handleRemoveShareholder(idx)} className="btn btn-danger btn-sm">X</button>
                        </div>
                        <div className="col-md-3"></div>
                      </div>
                    </div>
                  ))}
                  <center>  <button type="button" onClick={this.handleAddShareholder} className="btn btn-info">Add Field</button>
                    <button className="btn btn-success">Submit</button></center>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

    )
  }
}

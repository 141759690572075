import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

import queryString from "query-string";
class Sidebar extends Component {
  constructor(props) {
    super(props);
    const parsed = window.location.href.match(/login/g);
    let url1 = [];
    if (parsed !== null) {
      url1 = parsed;
    }
    this.state = {
      url: url1,
    };
  }

  handleClick = (a) => {
    var x, i;
    x = document.querySelectorAll("li.slide");
    for (i = 0; i < x.length; i++) {
      if (i == a) {
      } else {
        x[i].classList.remove("is-expanded");
      }
    }
  };

  render() {
    if (this.props.location.pathname == "/login") {
      // var element1 = document.querySelector("a");
      // alert(element1)
      // element1.classList.remove("app-content");
      // var element2 = document.querySelector("b");
      // element2.classList.remove("side-app");
      // var element3 = document.querySelector("c");
      // element3.classList.remove("main-content");
      // var element4 = document.querySelector("d");
      // element4.classList.remove("container-fluid");
      // var element5 = document.querySelector("d");
      // element5.classList.remove("pt-8");
      return null;
    }

    return (
      <div>
        <aside className="app-sidebar ">
          <div className="sidebar-img">
            <a className="navbar-brand" href="index.html">
              <img
                alt="..."
                className="navbar-brand-img main-logo"
                src="assets/img/brand/logo.jpg"
              />{" "}
              <img
                alt="..."
                className="navbar-brand-img logo"
                src="assets/img/brand/logo.jpg"
              />
            </a>
            <ul className="side-menu">
              <li className="slide" onClick={() => this.handleClick(0)}>
                <Link className="side-menu__item" to="/">
                  <i className="side-menu__icon fe fe-home"></i>
                  <span className="side-menu__label">Dashboard</span>
                </Link>
              </li>
              <li className="slide" onClick={() => this.handleClick(1)}>
                <a className="side-menu__item" data-toggle="slide" href="#">
                  <i className="side-menu__icon fas fa-arrows-alt"></i>
                  <span className="side-menu__label">Service Details</span>
                  <i className="angle fa fa-angle-right"></i>
                </a>
                <ul className="slide-menu">
                  <li>
                    <Link to="/category" className="slide-item">
                      View Menu
                    </Link>
                  </li>
                  <li>
                    <Link to="/subcategory" className="slide-item">
                      View Submenu
                    </Link>
                  </li>
                  <li>
                    <Link to="/service" className="slide-item">
                      View Service
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="slide" onClick={() => this.handleClick(2)}>
                <a className="side-menu__item" data-toggle="slide" href="#">
                  <i className="side-menu__icon fas fa-certificate"></i>
                  <span className="side-menu__label">Banner&Coupon</span>
                  <i className="angle fa fa-angle-right"></i>
                </a>
                <ul className="slide-menu">
                  <li>
                    <Link to="/viewbanner" className="slide-item">
                      View Banner
                    </Link>
                  </li>
                  <li>
                    <Link to="/view_coupon" className="slide-item">
                      View Coupon
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="slide" onClick={() => this.handleClick(3)}>
                <a className="side-menu__item" data-toggle="slide" href="#">
                  <i className="side-menu__icon fas fa-dharmachakra"></i>
                  <span className="side-menu__label">User</span>
                  <i className="angle fa fa-angle-right"></i>
                </a>
                <ul className="slide-menu">
                  <li>
                    <Link to="/user" className="slide-item">
                      View User
                    </Link>
                  </li>

                  <li>
                    <Link to="/viewconsaltent" className="slide-item">
                      View Consultant
                    </Link>
                  </li>
                  <li>
                    <Link to="/viewfreelancer" className="slide-item">
                      View freelancer
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="slide" onClick={() => this.handleClick(4)}>
                <a className="side-menu__item" data-toggle="slide" href="#">
                  <i className="side-menu__icon fas fa-layer-group"></i>
                  <span className="side-menu__label">Booking</span>
                  <i className="angle fa fa-angle-right"></i>
                </a>
                <ul className="slide-menu">
                  <li>
                    <Link to="/viewinquery" className="slide-item">
                      View Booking
                    </Link>
                  </li>
                  <li>
                    <Link to="/addinquery" className="slide-item">
                      Add Booking
                    </Link>
                  </li>
                  <li>
                    <Link to="/customizebooking" className="slide-item">
                      Custom Booking
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="slide" onClick={() => this.handleClick(5)}>
                <a className="side-menu__item" data-toggle="slide" href="#">
                  <i className="side-menu__icon ion-help-buoy"></i>
                  <span className="side-menu__label">City&Review</span>
                  <i className="angle fa fa-angle-right"></i>
                </a>
                <ul className="slide-menu">
                  <li>
                    <Link to="/view_city" className="slide-item">
                      View City
                    </Link>
                  </li>
                  <li>
                    <Link to="/review" className="slide-item">
                      Review
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="slide" onClick={() => this.handleClick(6)}>
                <a className="side-menu__item" data-toggle="slide" href="#">
                  <i className="side-menu__icon fas fa-certificate"></i>
                  <span className="side-menu__label">Video</span>
                  <i className="angle fa fa-angle-right"></i>
                </a>
                <ul className="slide-menu">
                  <li>
                    <Link to="/viewvideo" className="slide-item">
                      View Video
                    </Link>
                  </li>
                  <li>
                    <Link to="/addvideo" className="slide-item">
                      Add Video
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="slide" onClick={() => this.handleClick(7)}>
                <a className="side-menu__item" data-toggle="slide" href="#">
                  <i className="side-menu__icon fas fa-star"></i>
                  <span className="side-menu__label">Complete Service</span>
                  <i className="angle fa fa-angle-right"></i>
                </a>
                <ul className="slide-menu">
                  <li>
                    <Link to="/view_complete_service" className="slide-item">
                      View Complete Service
                    </Link>
                  </li>
                  <li>
                    <Link to="/add_complete_service" className="slide-item">
                      Add Complete Service
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="slide" onClick={() => this.handleClick(8)}>
                <a className="side-menu__item" data-toggle="slide" href="#">
                  <i className="side-menu__icon fas fa-star"></i>
                  <span className="side-menu__label">Library</span>
                  <i className="angle fa fa-angle-right"></i>
                </a>
                <ul className="slide-menu">
                  <li>
                    <Link to="/viewcategorylaibrary" className="slide-item">
                      Library Category
                    </Link>
                  </li>
                  <li>
                    <Link to="/viewlibrary" className="slide-item">
                      Library
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="slide" onClick={() => this.handleClick(9)}>
                <a className="side-menu__item" data-toggle="slide" href="#">
                  <i className="side-menu__icon fas fa-star"></i>
                  <span className="side-menu__label">Career&Partner</span>
                  <i className="angle fa fa-angle-right"></i>
                </a>
                <ul className="slide-menu">
                  <li>
                    <Link to="/carear" className="slide-item">
                    Career
                    </Link>
                  </li>
                  <li>
                    <Link to="/partner" className="slide-item">
                      Partner
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="slide" onClick={() => this.handleClick(10)}>
                <a className="side-menu__item" data-toggle="slide" href="#">
                  <i className="side-menu__icon fas fa-star"></i>
                  <span className="side-menu__label">Offer</span>
                  <i className="angle fa fa-angle-right"></i>
                </a>
                <ul className="slide-menu">
                  <li>
                    <Link to="/offer" className="slide-item">
                      Offer
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="slide" onClick={() => this.handleClick(11)}>
                <a className="side-menu__item" data-toggle="slide" href="#">
                  <i className="side-menu__icon fas fa-star"></i>
                  <span className="side-menu__label">Service provider</span>
                  <i className="angle fa fa-angle-right"></i>
                </a>
                <ul className="slide-menu">
                  <li>
                    <Link to="/viewscategory" className="slide-item">
                      Service provider
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </aside>
      </div>
    );
  }
}
export default withRouter(Sidebar);

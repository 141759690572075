import React, { Component } from 'react'
import axios from 'axios';
import {checkAuth} from '../../utils/auth';
import {post,authformpost} from '../../utils/service'
import {toast} from 'react-toastify';  
import 'react-toastify/dist/ReactToastify.css';
import queryString from 'query-string';
import { Link, withRouter } from 'react-router-dom'
export default class Edit_city extends Component {
    constructor(props) {
        super(props)
        let url = this.props.location.search;
        let params = queryString.parse(url);
        console.log(params)
        this.state = {
            city_id:params.city_id,
             
        }
    }
    handleSubmit = (e) => {
        e.preventDefault();
        var formdata = new FormData(e.target);
        authformpost('/api/editCity',formdata)
        .then((data) => {  
          toast.dark(data.data.msg,  
          {position: toast.POSITION.BOTTOM_CENTER,autoClose:3000})
          this.props.history.push('/view_city'); 
        }).catch((err)=>{
            this.setState({loading:false});
          })
      }
    
    render() {  

        return (
        <div>
            <div className="page-header mt-0 p-3">
                <h3 className="mb-sm-0">City Edit</h3>
                <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                        <Link to="/"><i className="fe fe-home"></i></Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">City Edit</li>
                </ol>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card shadow">
                        <div className="card-header">
                            <h2 className="mb-0">City Edit</h2>
                        </div>
                        <form onSubmit={this.handleSubmit}>
                         <div className="card-body">
                            <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <input type="hidden" name="city_id" value={this.state.city_id} id="city_id"></input>
                                            <label className="form-label">City Name</label>
                                            <input type="text" className="form-control" name="example-text-input" id="city_name" name="city_name" placeholder="City Name" />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group m">
                                            <button className="btn btn-success">Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div> 
                </div>
            </div>  
        </div>
    )
  }
}

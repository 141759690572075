import React, { Component } from "react";
import axios from "axios";
import { checkAuth } from "../../utils/auth";
import { authpost, post, authformpost, get,authget } from "../../utils/service";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import { Link, withRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { imageValid } from "../../utils/imageValid";
// import {UserContext} from './../../content/App'
toast.configure();
export default class TaskAssign extends Component {
  constructor(props) {
    super(props);

    this.state = {
      task_name: "",
      task_image: "",
      task_description: "",
      taken_id:"",
      loading: false,
      freelancer:[],
    };
    this.validator = new SimpleReactValidator({
      messages: {
        in: "Passwords need to match!",
      },
    });
  }

  handleChange = (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };

  componentDidMount(){
    authget("/api/viewFreelancer")
        .then((res) => {
          // console.log(res?.data)
          if (res.data.status == false) {
            this.setState({
              loading: false,
            });
          } else {
            this.setState({
              freelancer: res?.data?.freelancer,
            });
          }

        })
        .catch((err) => {
          this.setState({ loading: false });
        });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    var formdata = new FormData(e.target);
    if (this.validator.allValid()) {
      authformpost("/api/addTask", formdata)
        .then((res) => {
          if (res.data.status == false) {
            this.setState({
              loading: false,
            });

            toast.error(res.data.msg, {
              position: toast.POSITION.BOTTOM_CENTER,
              autoClose: 3000,
            });
          } else {
            this.setState({
              loading: false,
            });
            toast.dark(res.data.msg, {
              position: toast.POSITION.BOTTOM_CENTER,
              autoClose: 3000,
            });
            this.props.history.push('/Viewtask');
          }
        })
        .catch((err) => {
          this.setState({ loading: false });
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
      this.setState({ loading: false });
    }
  };
  render() {
    return (
      <div>
        <div className="page-header mt-0 p-3">
          <h3 className="mb-sm-0">Add Task</h3>
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <Link to="/">
                <i className="fe fe-home"></i>
              </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Add Task 
            </li>
          </ol>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card shadow">
              <div className="card-header">
                <h2 className="mb-0">Add Task </h2>
              </div>
              <form onSubmit={this.handleSubmit}>
                <div className="card-body">
                  <div className="row">
                  <div className="col-md-12">
                      <div className="form-group">
                        <label className="form-label"  >Select Freelancer</label>
                        <select name="taken_id" id="taken_id" className="form-control" onChange={this.handleChange}>
                          <option value="" disabled selected> Select Freelancer </option>
                        {this?.state?.freelancer&&this?.state?.freelancer?.map((obj,i)=>
                          <option value={obj?.id}>{obj?.name}</option>
                        )}
                        </select>
                        {this.validator.message(
                          "taken_id",
                          this.state.taken_id,
                          "required",
                          { className: "text-danger" }
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">Task Name</label>
                        <input
                          type="text"
                          className="form-control"
                          name="task_name"
                          id="task_name"
                          placeholder="Enter Task Name"
                          onChange={this.handleChange}
                          value={this.state.task_name}
                        />
                        {this.validator.message(
                          "task_name",
                          this.state.task_name,
                          "required",
                          { className: "text-danger" }
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">Task Image</label>
                        <input
                          type="file"
                          className="form-control"
                          name="task_image"
                          id="task_image"
                          placeholder="Enter Task Image"
                          onChange={this.handleChange}
                          value={this.state.task_image}
                        />
                        {this.validator.message(
                          "task_image",
                          this.state.task_image,
                          "required",
                          { className: "text-danger" }
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="form-label">Task Description</label>
                        <textarea
                          className="form-control"
                          placeholder="Task Description"
                          name="task_description"
                          id="task_description"
                          onChange={this.handleChange}
                        >
                          {" "}
                        </textarea>
                        {this.validator.message(
                          "task_description",
                          this.state.task_description,
                          "required",
                          { className: "text-danger" }
                        )}
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form-group m">
                        <br></br>
                        <center>
                          <button
                            className="btn btn-success"
                            disabled={this.state.loading ? true : false}
                          >
                            Submit
                          </button>
                        </center>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

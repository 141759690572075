import Add_inquery from '../components/Inquiry_component/Add_inquery'
import {connect} from 'react-redux'
import {addToCart} from '../services/Actions/actions'
const mapStateToProps=state=>({
    data:state.addItems
})
const mapDispatchToProps=dispatch=>({
    

})
export default connect(mapStateToProps,mapDispatchToProps)(Add_inquery)

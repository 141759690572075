
import Add_banner from '../components/Banner_component/Add_banner'

import {connect} from 'react-redux'
import {addToCart} from '../services/Actions/actions'
const mapStateToProps=state=>({
    data:state.addItems
})
const mapDispatchToProps=dispatch=>({
    

})
export default connect(mapStateToProps,mapDispatchToProps)(Add_banner)

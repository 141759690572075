import React,{Component,Suspense,lazy} from 'react'
import {connect} from 'react-redux'
import {addToCart} from '../services/Actions/actions'
import Loding from "../components/Home_componets/Loding";
const Viewuser=lazy(()=>import('../components/User_components/Viewuser'))


function ViewuserContainer() {
    return (
        <div>
           <Suspense fallback={<div> <Loding/></div>}> 
                    <Viewuser/>    
            </Suspense> 
        </div>
    )
}

const mapStateToProps=state=>({
    data:state.addItems
})
const mapDispatchToProps=dispatch=>({
    

})
export default connect(mapStateToProps,mapDispatchToProps)(ViewuserContainer)
import React, { Component } from 'react'
import { imageValid } from '../../utils/imageValid';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import axios from 'axios';
import { checkAuth } from '../../utils/auth';
import { post, authformpost, authget } from '../../utils/service'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SimpleReactValidator from "simple-react-validator";
import { Link, withRouter } from 'react-router-dom'


export default class AddLibrary extends Component {
    constructor(props) {
        super(props)

        this.state = {
         libraryCatagory:[],
         laibrary_cat_id:'',
         laibrary_name:'',
         pdf:'',
         librarytype:'Video',
        }
        this.validator = new SimpleReactValidator();
    }
    componentDidMount(){
        authget(`/api/showLCategory`)
        .then((res) => {
            if (res.status == 200) {
               const filterdata = res.data.allLCategory.filter(gs=>gs.laibrary_cat_status === 1)

                this.setState({
                    libraryCatagory: filterdata,
                });
            }
        })
        .catch((err) => {
            console.log(err);
        });
    }

    handleChange = e => {
        e.preventDefault();
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ loading: true });
        var formdata = new FormData(e.target);
        if (this.validator.allValid()) {
            authformpost('/api/addLibrary', formdata)
                .then((data) => {
                    toast.dark(data.data.msg,
                        { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3000 })
                    // this.props.history.push('/viewcategorylaibrary');
                }).catch((err) => {
                    this.setState({ loading: false });
                })
        } else {
            this.validator.showMessages();
            this.forceUpdate();
            this.setState({ loading: false });
        }
    }
    render() {
        return (
            <div>
                <div>
                    <div className="page-header mt-0 p-3">
                        <h3 className="mb-sm-0">Add Library</h3>
                        <ol className="breadcrumb mb-0">
                            <li className="breadcrumb-item">
                                <Link to="/"><i className="fe fe-home"></i></Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Add Library</li>
                        </ol>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card shadow">
                                <div className="card-header">
                                    <h2 className="mb-0">Add Library</h2>
                                </div>
                                <form onSubmit={this.handleSubmit}>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label">Library Name</label>
                                                    <input type="text" className="form-control" name="laibrary_name" id="laibrary_name" placeholder="Laibrary Name" onChange={this.handleChange} value={this.state.laibrary_name} />
                                                    {this.validator.message('laibrary_name', this.state.laibrary_name, 'required', { className: 'text-danger' })}
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label">Choose Library Type</label>
                                                        <select name="librarytype" id="librarytype"  className="form-control" onChange={this.handleChange} value={this.state.librarytype}>
                                                            <option value="" disabled selected>Select Option</option>
                                                            <option value="Video">Video</option>
                                                            <option value="Pdf">Pdf</option>
                                                        </select>
                                                    {this.validator.message('librarytype', this.state.librarytype, 'required', { className: 'text-danger' })}
                                                </div>
                                            </div>
                                            
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label">Choose Library Category</label>
                                                        <select name="laibrary_cat_id" id="laibrary_cat_id" className="form-control" onChange={this.handleChange} value={this.state.laibrary_name} >
                                                           <option value="" disabled selected>Select Option </option>
 
                                                            {this.state.libraryCatagory.map((object,i)=>

                                                            <option value={object.id}>{object.laibrary_cat_name}({object.type})</option>
                                                           
                                                           )}

                                                        </select>
                                                    {this.validator.message('laibrary_cat_id', this.state.laibrary_cat_id, 'required', { className: 'text-danger' })}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group mb-0">
                                                    {this.state.librarytype === 'Video'?
                                                    <>
                                                    <label className="form-label">Ensert YouTube Link</label>
                                                    <input type="text" className="form-control" name="pdf" id="pdf" placeholder="Complete Service Image" multiple required />
                                                
                                                    </>:
                                                    <>
                                                    <label className="form-label">Choose File</label>
                                                    <input type="file" className="form-control" name="pdf" id="pdf" placeholder="Complete Service Image" multiple required />
                                                    </>
                                                    }
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <div className="form-group m"><br>
                                                </br>
                                                    <center><button className="btn btn-success">Submit</button></center>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

import React from 'react'
import Skeleton from "react-loading-skeleton";
export default function Loding() {
    return (
        <div>
            <div className="page-header mt-0 p-3">
                    <Skeleton  width={1050} />
            </div>
              <div className="row">
                <div className="col-xl-12">
                    <div className="card shadow">
                        <div className="card-header bg-transparent">
                        <div className="row align-items-center">
                            <div className="col-6">
                                 <Skeleton  width={500} height={300} />
                            </div>
                            <div className="col-6">
                                 <Skeleton  width={500} height={300} />
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
              </div>      
        </div>
    )
}
